.destinationDetail {
  width: 100%;
  height: fit-content;
  background: #f9f9f9;
}

.destinationDetail .strechContainer .strechSection .detailDesTitle {
  height: 35vh;
  font-size: 40px;
  font-weight: 800;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.destinationDetail .imgg {
  width: 100%;
  height: 70vh;
}

.destinationDetail .mainDetails {
  width: 100%;
  height: fit-content;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.destinationDetail .mainDetails .leftSection {
  width: 63%;
}

.destinationDetail .mainDetails .leftSection .h5 {
  margin-top: 30px;
  margin-bottom: -20px;
  font-weight: 700;
  text-transform: uppercase;
}

.destinationDetail .mainDetails .rightSection {
  position: relative;
  width: 33%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rightSection .stickySection {
  position: sticky;
  top: 0;
}

.bookDestination {
  display: flex;
  background: #f87474;
  width: 100%;
  box-shadow: 0 2px 4px 0 #dedede;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.bookDestination h4 {
  color: white;
}

.bookDestination h5 {
  color: white;
}

.bookDestination h6 {
  color: white;
}

.bookDestination span {
  color: white;
}

.bookDestination .participants {
  width: 100%;
}

.bookDestination .participants .totalParticipants {
  display: flex;
  justify-content: space-between;
}

.totalParticipants .participantCounter {
  background: white;
  border-radius: 30px;
  width: 40%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalParticipants .participantCounter button {
  color: black;
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  margin: 0px;
  font-size: 25px;
  cursor: pointer;
}

.totalParticipants .participantCounter button:hover {
  background: #f9f9f9;
}

.totalParticipants .participantCounter span {
  font-size: 20px;
  color: black;
}

.datePicker {
  margin-top: 15px;
  padding-bottom: 25px;
  width: 100%;
}

.datePicker .ant-picker-calendar-header span {
  color: black;
}

.datePicker .ant-picker-calendar-header .ant-picker-calendar-year-select {
  display: none;
}

.datePicker .ant-radio-button-wrapper:last-child {
  display: none;
}

.notAvailable {
  margin-top: 10px;
  display: none;
}

.notAvailable span {
  font-size: 15px;
  color: white;
}

.forLoading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookDestination .availableOptions {
  margin-top: 15px;
  width: 100%;
}

.bookDestination .availableOptions h5 {
  margin: 20px 0px;
}

.availableOptions .optionCard {
  background: #fad9a1;
  padding: 10px;
}

.availableOptions .optionCard .option {
  width: 100%;
}

.optionCard .option .flexOption {
  display: flex;
  justify-content: space-between;
}

.bookDestination .react-datepicker{
  border-radius: 0px;
}

.bookDestination .react-datepicker__header{
  background-color: white;
  border: none;
}

.bookDestination .react-datepicker__day--highlighted{
  border-radius: 0px;
}

.bookDestination .react-datepicker__day--selected{
  border-radius: 0px;
}

.bookDestination .react-datepicker__day--disabled{
  text-decoration-line: line-through;
}

.optionCard .option .flexOption span {
  font-weight: bold;
}

.availableOptions .optionCard .option div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.optionCard .option div span {
  color: black;
}

.availableOptions .optionCard .option h6 {
  color: black;
  line-height: 1.3;
  width: 60%;
}

.bookThis div {
  font-size: 18px;
  font-weight: 700;
}

.bookThis .time {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bookThis .time i {
  margin-top: 10px;
}

.bookThis .time .timeAvailable {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.bookThis .time .timeAvailable .timeBtn {
  background: #219653;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  margin: 5px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
}

.bookThis .time .timeAvailable .timeBtn:hover {
  background: #16ad57;
}

.bookThis .time .timeAvailable .timeBtn:focus {
  background-color: gray;
}

.bookThis .priceBreakdown {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
  cursor: pointer;
}

.bookThis .priceBreakdown p {
  font-weight: 400;
  margin: 0px;
  font-size: 15px;
}

.bookThis .priceBreakdown i {
  margin-left: 5px;
}

.bookThis .breakdownContent {
  display: none;
}

.bookThis .breakdownContent .participantTotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1.5px solid black;
}

.bookThis .breakdownContent .participantTotal h6 {
  font-size: 14px;
  color: black;
}

.bookThis .breakdownContent p {
  font-size: 13px;
  color: #16ad57;
  width: 100%;
  justify-self: flex-end;
}

.addToCart .successMsg{
    width: 100%;
    background: #8eeb8e;
    font-size: 17px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addToCart .successMsg h6{
    color: darkgreen;
}



.addToCart button{
  width: 100%;
  padding: 5px;
  border: none;
  background: #ef3f3e;
  border-radius: 30px;
  margin-top: 10px;
  color: white;
  font-weight: 700;
}


.bookThis .bookBtn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db0000;
  color: white;
  border: none;
  padding: 20px;
  border-radius: 35px;
  cursor: pointer;
}

.bookThis .bookBtn:hover {
  background: #ff5454;
}

.bookThis .bookBtn h6 {
  width: 60%;
  margin: 0px;
}

.bookThis .bookBtn i {
  font-size: 20px;
}

.paymentModal {
  width: 90%;
  background: white;
  margin: 30px;
}

.paymentModal .checkout {
  display: flex;
  justify-content: space-between;
  background: #15a763;
  padding: 10px;
}

.paymentModal .checkout div h4 {
  color: white;
}

.paymentModal .checkout div p {
  color: white;
}

.paymentModal .checkout .fa-xmark {
  color: black;
  background: white;
  border-radius: 50%;
  height: 38px;
  width: 39px;
  font-size: 25px;
  padding: 6px 12px;
  cursor: pointer;
}

.paymentModal .destinationOverview {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #fad9a1;
}

.destinationOverview .overviewImg {
  width: 40%;
  margin-right: 15px;
}

.destinationOverview .overviewImg img {
  width: 100% !important;
  height: 100% !important;
}

.destinationOverview .destinationInfo {
  width: 65%;
}

.destinationInfo .h6 {
  font-weight: normal;
}

.destinationOverview .priceBreakdown2 p {
  font-weight: 700;
  font-size: 20px;
}

.priceBreakdown2 .breakdownContent2 .participantTotal2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  border-bottom: 1.5px solid black;
}

.priceBreakdown2 .breakdownContent2 p {
  font-size: 14px;
  color: #16ad57;
}

.css-79ws1d-MuiModal-root {
  display: flex;
  justify-content: center;
}

.paymentModal .paymentContainer {
  display: flex;
  justify-content: space-between;
  background: #f8e1b9;
  padding: 10px;
}

.paymentContainer .paymentContact {
  width: 45%;
}

.perr {
  color: red;
  font-weight: 500;
}

#countryCode {
  width: 45%;
  padding: 5px;
  border: none;
}

.paymentContact p {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

.paymentContact .contactInput {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 25px;
  background-color: white;
  margin-top: 20px;
}

.paymentContact .contactInput input {
  border: none;
  width: 90%;
  border-radius: 25px;
  padding: 10px;
}

.paymentContainer .paymentDetails {
  width: 50%;
  height: fit-content;
}

.paymentDetails .paymentCard {
  padding: 20px;
}

.paymentDetails .paymentCard {
  background-color: #ffaf902e;
  width: 100%;
  height: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.paymentCard .cardTitle {
  display: flex;
  justify-content: space-between;
}

.paymentCard .cardTitle2 div {
  display: flex;
  margin-bottom: 10px;
}

.paymentCard span {
  font-size: 15px;
}

.paymentCard .cardTitle2 div h6 {
  margin-left: 10px;
}

.paymentCard .cardTitle h6 {
  margin: 0px;
}

.paymentCard .cardTitle input[type="radio"] {
  width: 20px;
  cursor: pointer;
}

.paymentCard .cardTitle .cardImages img {
  width: 30px;
  margin-left: 10px;
}

.paymentCard .cardItself {
  background: #7a78fc;
  margin-top: 10px;
  height: 90%;
  border-radius: 15px;
  padding-top: 25px;
}

.cardItself .cardLine {
  background: black;
  padding: 20px;
  width: 100%;
}

.cardItself .cardInput {
  margin: 15px 20px;
  padding: 10px;
  background: white;
  border-radius: 20px;
  width: 90%;
  display: flex;
  align-items: center;
}

.cardInput input {
  font-size: 15px;
  color: #9ca3b6;
  font-weight: bold;
  border: none;
  width: 100%;
  margin-left: 10px;
}

.cardItself .cardTwoInputs {
  display: flex;
}

.cardItself .cardTwoInputs input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.paymentDetails button {
  border: none;
  background: #2b2899;
  color: white;
  font-weight: 600;
  border-radius: 20px;
  padding: 7px;
  font-size: 17px;
  margin-top: 20px;
  width: 100%;
}

.paymentDetails .paymentCancel {
  color: #16ad57;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.paymentDetails .paymentCancel i {
  color: #16ad57;
  font-size: 15px;
  margin-right: 15px;
}

.paymentDetails button:hover {
  background: #7a78fc;
}

.paymentDetails .cardCheckboxes {
  margin-top: 20px;
}

.paymentDetails .cardCheckboxes .cardCheckbox {
  display: flex;
  align-items: center;
}

.cardCheckboxes .cardCheckbox input[type="checkbox"] {
  width: 20px;
}

.cardCheckboxes .cardCheckbox p {
  margin: 0px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}

.reuseableContainer {
  display: flex;
  background: white;
  width: 100%;
  box-shadow: 0 2px 4px 0 #dedede;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  margin-top: 30px;
  overflow: hidden;
}

.bookDestination .spinnerContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.bookDestination input {
  margin-top: 0px;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.detailInput {
  margin-top: 10px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.bookDestination .rooms {
  position: absolute;
  bottom: -23%;
  display: none;
  width: 225px;
  height: 180px;
  position: absolute;
  background-color: white;
  z-index: 3;
  border: 1px solid #dedede;
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

.detailBtn {
  padding: 6px 14px;
  width: 100%;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 0;
  border: 1px solid #ef3f3e;
  background-color: #ef3f3e;
  -webkit-box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 12%);
  box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 12%);
  color: white;
  font-weight: 700;
}

.cardCheckboxes .cardCheckbox input[type="checkbox"] {
  width: 20px;
  cursor: pointer;
}

.bookDestination h5 {
  font-weight: 600;
}

.newsLetter {
  display: flex;
  background: white;
  width: 100%;
  box-shadow: 0 2px 4px 0 #dedede;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  margin-top: 30px;
}

.newsLetter .newsContainer h5 {
  font-weight: 700;
}

.newsLetter .newsContainer p {
  color: rgba(0, 0, 0, 0.55);
}


/* Title */

.headingTitles {
  display: flex;
  flex-wrap: wrap;
  padding: 13px;
  background: white;
  box-shadow: 0 2px 4px 0 #dedede;
  width: 100%;
}

.headingTitles li {
  list-style: none;
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-transform: capitalize;
}

.headingTitles a {
  text-decoration: none;
  color: black;
}

.headingTitles li:hover {
  color: #ef3f3e;
}


/* About This */

.aboutThis{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
}

.aboutThis i{
  margin-right: 10px;
  font-size: 20px;
  
}

.abooutThis .fa-calendar-check{
  color: green;
}

.aboutThis p{
  color: #63687a;
  font-weight: 500;
}


/* expHighlights */

.expHighlights{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 30px;
  border-bottom: 1px solid #dcdfe4;
}

.expHighlights h6{
  font-weight: 700;
  width: 155px;
}  

.expDetails{
  width: 70%;
}

.expDetails p{
  font-weight: 600;
  margin-bottom: 0px;
}

.expDetails span{
    color: red;
    cursor: pointer;
}

.expDetails ul li{
  font-weight: 600;
}


/* hightlight */

.hightlight .highlightContainer p {
  color: rgba(0, 0, 0, 0.55);
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.hightlight .highlightContainer p a {
  color: #ef3f3e;
  margin-left: 5px;
  cursor: pointer;
}

.hightlight .highlightContainer p a:hover {
  color: #ef3f3e;
  text-decoration: underline;
}

.hightlight .highlightContainer .clusionContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hightlight .highlightContainer .inclusion {
  width: 45%;
}

.hightlight .highlightContainer .inclusion h5,
.exclusion h5 {
  font-weight: 700;
}

.hightlight .highlightContainer .inclusion li {
  list-style-type: square;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.7);
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.7;
  letter-spacing: 0.04em;
}

.hightlight .highlightContainer .exclusion {
  width: 47%;
}

.hightlight .highlightContainer .exclusion li {
  list-style-type: square;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.7);
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.7;
  letter-spacing: 0.04em;
}

.hightlight .highlightContainer img {
  width: 100%;
  height: 160px;
  margin-top: 20px;
}

.itinerary h5 {
  font-weight: 700;
}

.journeyCard {
  width: 100%;
  margin-top: 15px;
}

.journeyCard .cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  background: #f9f9f9;
}

.journeyCard .cardTitle h6 {
  font-weight: 700;
  margin-bottom: 0px;
  text-transform: capitalize;
}

.journeyCard .cardDetail {
  border: 1px solid rgba(0, 0, 0, 0.055);
  padding: 10px;
  display: none;
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
    height: 90px;
  }
  to {
    opacity: 1;
    height: 195px;
  }
}

.journeyCard .cardDetail p {
  color: #444444;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.journeyCard .cardDetail .benefits {
  display: flex;
  flex-direction: column;
}

.journeyCard .cardDetail .benefits span {
  text-transform: capitalize;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  color: rgb(92, 91, 91);
  font-weight: 500;
}

.gallery {
  padding: 30px 0px;
  background: #f9f9f9;
  width: 100%;
}

.gallery {
  padding: 30px;
  background: #f9f9f9;
  width: 100%;
}

.gallery .imageFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.gallery .imageFlex .styleImages {
  display: flex;
  position: relative;
}

.gallery .imageFlex .styleImages button {
  position: absolute;
  top: 5%;
  left: 5%;
  padding: 7px;
  border: none;
  background: white;
  font-weight: 600;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.gallery .imageFlex .styleImages .styleImages2 {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  width: 40%;
}

.gallery .imageFlex .styleImages .firstImage {
  width: 60%;
  height: 70vh;
}

.gallery .imageFlex .styleImages .secondImage {
  width: 100%;
  height: 40vh;
}

.gallery .imageFlex .styleImages .styleImages2 .thirdImage {
  width: 50%;
  height: 97%;
  margin: 10px 0px;
}

.gallery .imageFlex img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: ease-in-out 0.4s;
  object-fit: cover;
}

.image-gallery {
  width: 95%;
}

.image-gallery-thumbnail {
  width: 145px !important;
  height: 100px !important;
  filter: contrast(0.5);
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  width: 100%;
  height: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  width: 100%;
  height: 100%;
}

.image-gallery-left-nav {
  left: -10% !important;
}

.image-gallery-right-nav {
  right: -10% !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 30px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  outline: none;
  border: none !important;
  filter: contrast(1);
}

.allImgModal {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  position: relative;
  animation: modal 0.3s ease-in;
  transition: 0.3s ease;
}

@keyframes modal {
  from {
    opacity: 0;
    filter: blur(2);
  }
  to {
    opacity: 1;
    filter: blur(0);
  }
}

.allImgModal .carousel {
  width: 100%;
  height: 100%;
}

.allImgModal .carousel-inner {
  width: 100%;
  height: 100%;
}

.allImgModal i {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 0%;
  font-size: 30px;
  z-index: 100;
}

.fullImage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
}

.fullImage img {
  width: 100%;
  height: 100%;
  animation: full-image ease-in-out 0.5s;
  transition: ease-in-out 0.5s;
}

@keyframes full-image {
  from {
    width: 50%;
    height: 40%;
  }
  to {
    width: 95%;
    height: 95%;
  }
}

.fullImage i {
  font-size: 35px;
  color: rgba(0, 0, 0, 0.5);
  background: #f9f9f93b;
  position: absolute;
  top: 5%;
  right: 4%;
  padding: 7px;
  cursor: pointer;
  transition: ease 0.5s;
}

.fullImage i:hover {
  color: black;
}

.hotel {
  display: flex;
  margin-top: 20px;
}

.hotel img {
  width: 35%;
  height: 30%;
  cursor: pointer;
}

.hotel .hotelDetail {
  padding-right: 15px;
  margin-left: 50px;
}

.hotel .hotelDetail h5 {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
  text-transform: capitalize;
  margin-bottom: 0;
  color: black;
  font-weight: 800;
  line-height: 1.5;
  cursor: pointer;
}

.hotel .hotelDetail p {
  color: rgba(0, 0, 0, 0.7);
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.hotel .hotelDetail .hotelRating i {
  color: gold;
  font-size: 14px;
  margin-left: 5px;
}

.hotel .hotelDetail .facility .facility-box img {
  width: 22px;
  height: 22px;
}

.hotel .hotelDetail .facility .facility-box span {
  font-size: 12px;
}

.hotel .hotelDetail .facility {
  display: flex;
  width: 70%;
}

.hotel .hotelDetail .facility .facility-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
}

/* FAQ */


.faq h4{
  font-weight: 700;
}


.qaSuccMsg{
    width: 30%;
    background: lightgreen;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 5%;
    right: 35%;
    border-radius: 6px;
    z-index: 100000;
}


.qaSuccMsg p{
  font-size: 16px;
  margin-bottom: 0px;
  color: green;
}


.faq .qaSearch{
    border: 1px solid black;
    padding: 3px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.faq .qaSearch i{
  cursor: pointer;
}

.faq .qaSearch input{
    width: 95%;
    border: none;
}

.faq .qaContainer{
    display: flex;
    align-items: center;
    width: 100%;
}

.faq .qaVote{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 20px;
  border-right: 1px solid lightgray;
}


.faq .qaVote i{
    font-size: 35px;
    color: grey;
    height: 30px;
    cursor: pointer;
    transition: .5s ease;
}

.faq .qaVote i:hover{
  color: black;
}

.faq .qaVote span{
  font-size: 15px;
}


.faq .faqError{
  display: flex;
  justify-content: center;
}

.faq .faqContainer{
  margin-top: 40px;
  padding-left: 10px;
  width: 100%;
}

.faq .faqContainer .question{
  display:  flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.question .qaEditQue span{
  color: red;
  margin-right: 20px;
  cursor: pointer;
}

.faq .faqContainer .question p{
  color: #007185;
  width: 90%;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
 
.faq .faqContainer .answer{
  display: flex;
  flex-direction: column;
  width: 90%;
  flex-wrap: wrap;
}

.answer .qaEditAns span{
  color: red;
  margin-right: 20px;
  cursor: pointer;
}

.faq .faqContainer .answer .fa-circle-user{
  font-size: 20px;
}

.faq .faqContainer .answer p{
  width: 90%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq .faqContainer .answer p:last-child{
  color: gray;
  padding-top: 2px;
  font-weight: 400;
  margin-bottom: 15px;
}

.faq .faqContainer .answer .moreAnsBtn{
  color: #ef3f3e;
  cursor: pointer;
  width: fit-content;
}

.faq .faqContainer .answer .moreAnsBtn:hover{
  background: #80808008;
}



.faq button{
    border: none;
    padding: 7px;
    width: 20%;
    margin-top: 25px;
    border-radius: 7px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    background-color: rgb(239, 63, 62);
    border-radius: 50px;
    color: #fff;
}
.faq button:hover{background-color: #0071eb;}
.faq .postQuestion{
  border: 1px #F0F2F2 solid;
  background-color: #F0F2F2!important;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
}

.faq .postQuestion span{
  font-weight: 700;
}

.faq .postQuestion button{
  margin-top: 0px;
  width: 160px;
}

.qaModal{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.qaModal .qaModalContent{
  width: 50%;
  background: white;
  height: 40%;
  border-radius: 20px;
}

.qaModal .qaModalContent .qaModalTitle{
    position: relative;
    padding: 21px;
    font-size: 15px;
    font-weight: 700;
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
}

.qaModal .qaModalContent .qaModalTitle i{
  position: absolute;
  right: 5%;
  font-size: 25px;
  cursor: pointer;
}


.qaModalContent .qaInput{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 65%;
}


.qaInput input{
  width: 80%;
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}


.qaInput .qaMsg{
  background: lightgreen;
  padding: 15px;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qaInput .qaMsg p{
    margin-bottom: 0px;
    font-size: 15px;
    color: green;
}

.qaInput button{
  width: 25%;
  padding: 17px;
  margin-top: 20px;
  border: none;
  background: #ef3f3e;
  font-size: 17px;
  font-weight: 400;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qaInput button:hover{
  background: white;
  border: 1px solid #ef3f3e;
  color: #ef3f3e;
}

/* Review Section */

.reviews h4 {
  font-weight: 700;
}

.reviews .customerReviews {
  height: 35vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.customerReviews .overallRating {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ebeef1;
}

.overallRating h6 {
  width: 100%;
  font-weight: 700;
}

.overallRating h5 {
  display: none;
}

.overallRating .overallContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overallContainer h1 span {
  font-size: 25px;
  color: gray;
}

.overallRating .overallContainer p {
  font-weight: 700;
  color: grey;
}

.overallRating .overallContainer .overallStars {
  color: gold;
}

.overallContainer .overallStars span {
  margin-left: 5px;
  font-size: 40px !important;
}

.customerReviews .reviewSummary {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reviewSummary h6 {
  width: 100%;
  font-weight: 700;
}

.reviewSummary .progressBar span {
  font-weight: 600;
}

.reviewSummary .progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin-top: 20px;
}

.reviewSummary .progress {
  width: 60%;
  height: 8px !important;
  margin-bottom: 0px !important;
}

.reviews .reviewSortBy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 20px 0px;
}

.reviews .reviewSortBy .reviewFlex {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.reviewSortBy .reviewSearch {
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
}

.reviews .filterModalBtns {
  width: 100%;
  border: 2px solid #ef3f3e;
  border-radius: 25px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  display: none;
}

.reviews .filterModalBtns button {
  background: white;
  color: #ef3f3e;
  font-size: 18px;
  font-weight: 600;
  width: 45%;
  border: none;
}

.reviews .filterModalBtns button:first-child {
  border-right: 2px solid #ef3f3e;
}

.reviewSortBy .reviewSearch input {
  border: none;
  font-weight: 600;
  width: 80%;
}

.reviewSortBy .reviewSearch i {
  margin: 0px 5px;
}

.reviews .rvh5 {
  font-size: 17px;
  font-weight: 700;
  margin-right: 10px;
}

.reviews .reviewError {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviews .reviewError h5 {
  font-weight: 700;
}

.reviews .reviewBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.reviews .reviewBtn button {
  border: 2px solid #ef3f3e;
  border-radius: 25px;
  background: white;
  color: #ef3f3e;
  font-size: 16px;
  font-weight: 600;
  padding: 7px 25px;
}

.reviews .reviewBtn button:hover {
  border-color: #d13333;
  color: #d13333;
}

.reviewContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.reviewContainer .Div {
  width: 70%;
}

.reviewContainer .reviewFilter {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reviewFilter .addReviewBtn{
    margin-top: 30px;
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    background: #ef3f3e;
    color: white;
    border-radius: 5px;
}


.reviewFilter .addReview{
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reviewFilter .addReview .css-iljtu2-MuiRating-root{
  margin-top: 10px;
  font-size: 40px;
  margin-left: 10px;
}


.reviewFilter .addReview textarea{
    height: 100px;
    width: 100%;
    border: 1px solid #d9d9d9;
    font-size: 16px;
    font-weight: 500;
}


.reviewFilter .addReview .addReviewSucc{
  background: lightgreen;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.addReviewSucc p{
  margin-bottom: 0px;
  font-size: 16px;
  color: darkgreen;
}


.reviewFilter .addReview button{
  margin-top: 10px;
    width: 100%;
    border: none;
    padding: 6px;
    background: #f55b5a;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
}


.reviewFilter .addReview button:hover{
  background: #ef3f3e;
}


.reviewFilter .reviewFilterStars {
  margin-top: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #1a2b49;
  font-size: 1rem;
}

.reviewFilterStars label {
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
}

.reviewFilter .reviewFilterStars .css-ryrseu-MuiRating-root {
  font-size: 17px !important;
  margin-left: 5px;
}

.reviewFilter .reviewFilterStars input {
  margin-right: 15px;
}

.reviewFilter .reviewFilterStars i:first-child {
  margin-left: 15px;
}

.reviewFilter .reviewFilterStars i {
  color: gold;
  margin-left: 5px;
  font-size: 12px;
}

.review {
  border-bottom: 1px solid #ebeef1;
  padding-bottom: 35px;
  padding-top: 0px;
}

.review .rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;
}

.review .rating .stars {
  margin-right: 10px;
}

.review .rating .stars i {
  color: gold;
  font-size: 13px;
  margin-left: 3px;
}

.review .rating .createdAt {
  margin-bottom: 0px;
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 700;
  color: #858995;
}

.review .reviewBy {
  font-size: 14px;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 4px;
}

.review .comment p {
  color: #4a4a4a;
  line-height: 1.6;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}

.review .userComment {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.userComment span {
  background: #ef3f3e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  padding: 0px 15px;
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.userComment .userFrom p:first-child {
  font-weight: 600;
  margin-bottom: 3px;
}

.userComment .userFrom .userCountry {
  background: none;
  color: #000000d1;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  border-radius: 0px;
}

.userComment .userFrom p:last-child {
  margin-bottom: 0px;
  color: #4a4a4aad;
  font-weight: 500;
}

.userComment .helpful {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  font-weight: 600;
}

.userComment .helpful p {
  margin-left: 10px;
  color: #ef3f3e;
  width: 30px;
  padding: 2px 5px;
  height: 27px;
  cursor: pointer;
  border-radius: 3px;
}

.userComment .helpful p:hover {
  background: #bb2d2d;
  color: white;
}

.ant-select-arrow .anticon path {
  fill: black;
}

/* Policy Section */

.policy .paymentPolicy h6 {
  font-weight: 700;
  margin-left: -10px;
  margin-top: 20px;
}

.policy .paymentPolicy .policylist li {
  list-style-type: square;
}

.policyp {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.7);
  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
  line-height: 1.7;
  letter-spacing: 0.04em;
}

.policylist {
  display: flex;
}

/* Related Destination Section */

.moredest {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.moredest .eWjCzc,
.eAyARa {
  display: none;
}

.suggestion {
  width: 90%;
  height: 30vh;
  overflow: hidden;
  position: relative;
}

.suggestion img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-filter: blur(5px);
  filter: blur(1.5px);
  transition: ease-in-out 0.5s;
}

.suggestion img:hover {
  filter: blur(0px);
  transform: scale(1.1, 1.1);
}

.suggestion .text {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suggestion .text a {
  text-decoration: none;
}

.suggestion .text h5 {
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
}

.suggestion .text h5:hover {
  color: #ef3f3e;
}

.suggestion .text h6 {
  color: white;
  font-weight: 600;
  justify-self: center;
}

.suggestion .shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(231, 56, 39, 0) 100%
  );
  width: 100%;
  height: 30%;
}

.destinationDetail .stickyModal {
  display: none;
}

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookingModal{
    width: 90%;
    height: fit-content;
    margin-top: 15%;
    display: block;
    animation: modal-fade ease-in-out .3s;
    transition: ease-in-out .5s;
}

@keyframes modal-fade {
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
}

.bookingModal .headingContainer {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery .allImgModal2{
    display: none;
}

.bookingModal .headingContainer i {
  font-size: 20px;
  cursor: pointer;
}

.ant-modal-wrap {
  position: fixed;
  right: 0;
  border-radius: 15px !important;
  bottom: 0 !important;
  left: 0;
  top: auto !important;
  overflow: hidden !important;
  outline: 0;
  width: 100% !important;
  z-index: 1000;
}

.ant-modal {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  pointer-events: none;
  position: relative;
  top: 0 !important;
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}

@media (min-width: 1160px) {
  .bookDestination .spinner-border {
    position: absolute;
    top: 41%;
  }

  .destinationDetail .strechContainer .strechSection .detailDesTitle {
    height: 25vh;
  }
}

@media (max-width: 768px) {
  .hightlight .highlightContainer .clusionContainer {
    flex-direction: column;
  }

  .highlightContainer .clusionContainer .inclusion {
    width: 100%;
  }

  .highlightContainer .clusionContainer .exclusion {
    width: 100%;
    margin-top: 15px;
  }

  .headingTitles li {
    margin-left: 20px;
  }

  .allImgModal .carousel {
    height: 70%;
  }

  .destinationDetail .mainDetails {
    flex-direction: column;
  }

  .destinationDetail .mainDetails .leftSection {
    width: 100%;
  }

  .destinationDetail .mainDetails .rightSection {
    width: 100%;
    margin-top: 30px;
  }

  .paymentModal .paymentContainer {
    flex-direction: column;
  }

  .paymentContainer .paymentContact {
    width: 100%;
  }

  .paymentContainer .paymentDetails {
    width: 100%;
  }

  .bookDestination .availableOptions {
    width: 100%;
  }

  .reviews .customerReviews {
    flex-direction: column;
    height: fit-content;
  }

  .reviews #reviews {
    display: none;
  }

  .customerReviews .overallRating {
    width: 100%;
  }

  .customerReviews h6 {
    display: none;
  }

  .customerReviews h5 {
    display: block;
    font-size: 25px;
    font-weight: 700;
  }

  .customerReviews .reviewSummary {
    width: 100%;
  }

  .customerReviews h6 {
    display: none;
  }

  .reviewSummary .progressBar {
    padding: 0px 20px;
    width: 90%;
  }

  .reviewContainer .Div {
    width: 100%;
  }

  .reviewContainer .Div #allReviews{
    position: relative;
  }

  .reviewContainer .Div #allReviews button{
    position: absolute;
    top: 23%;
    right: 5%;
    border: none;
    background: none;
    font-size: 15px;
    font-weight: 700;
    color: red;
  }

  .reviewContainer .reviewFilter {
    display: none;
  }

  .reviews .reviewSortBy .reviewFlex {
    display: none;
  }

  .reviews .reviewSortBy .reviewSearch {
    width: 100%;
  }

  .reviews .filterModalBtns {
    display: block;
  }
}

@media (max-width: 570px) {
  .gallery .imageFlex img {
    width: 100%;
  }

  .suggestion {
    width: 100%;
    margin-top: 20px;
  }

  .hotel {
    flex-direction: column;
  }

  .hotel .hotelDetail {
    margin-left: 0px;
    margin-top: 10px;
  }

  .destinationDetail .stickyModal {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: -1px -2px 6px 0px rgb(168 168 168 / 30%);
  }

  .stickyModal button {
    background: white;
    border: none;
    width: 50%;
    font-weight: 700;
    text-transform: uppercase;
  }

  .stickyModal button:hover {
    color: #ef3f3e;
  }

  .stickyModal .bookingBtn {
    left: 0;
    height: 35px;
  }

  .stickyModal .enquiryBtn {
    right: 0;
    height: 35px;
    border-left: 1px solid #4a4a4a5e;
  }
}

@media (max-width: 500px) {
  .paymentModal .destinationOverview {
    flex-direction: column;
  }

  .destinationOverview .overviewImg {
    width: 100%;
  }

  .destinationOverview .destinationInfo {
    width: 100%;
    margin-top: 10px;
  }

  .allImgModal .carousel {
    height: 30%;
  }

  .gallery .imageFlex {
    display: none;
  }

  .gallery .allImgModal2 {
    display: block;
    width: 100%;
  }

  .gallery .allImgModal2 img {
    object-fit: contain;
  }

  .gallery {
    padding: 0px;
    margin-top: 20px;
  }

  .image-gallery {
    width: 100%;
  }

  .image-gallery-left-nav {
    left: 0% !important;
  }

  .image-gallery-right-nav {
    right: 0% !important;
  }

  .image-gallery-left-nav {
    left: 0% !important;
  }
  
  .image-gallery-right-nav {
    right: 0% !important;
  }
  
  .destinationDetail .strechContainer  .strechSection .detailDesTitle {
    height: 30vh;
    font-size: 23px;
    padding: 0px 8px;
  }


}

@media only screen and (max-width:786px){
  .faq button {width: 40%;}
}


@media (max-width: 450px) {
  .bookingModal {
    margin-top: 35%;
  }
}

.gift-button {
  padding-top: 20px;
}
