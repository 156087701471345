.banner{
    width: 100%;
    height: 100vh;
}

#banner_img{
    width: 100%;
    height: 100%;
}

.banner h6{
    position: absolute;
    top:50%;
    left: -5%;
    color: white;
    transform: rotate(271deg);

}


.banner .vl {
    border-left: 3px solid white;
    border-radius: 5px;
    height: 65px;
    position: absolute;
    transform: rotate(271deg);
    top: -25px;
    left: -55px;
  }

.banner .chngImg li{
    list-style: none;
    color: white;
    font-size: 30px;
    font-weight: bolder;
    cursor: pointer;
    margin-top: 5px;
}

.banner .chngImg{
    position: absolute;
    right: 5%;
    top: 40%;
    
}

.banner .search{
    width: 80%;
    height: 110px;
    padding: 0px 5px;
    position: absolute;
    top: 45%;
    left: 5%;
    display: flex;
    align-items: center;
   justify-content: space-between;
    background: white;
    border-radius: 15px;
    border: .5px solid rgba(128, 128, 128, 0.192);
    margin-left: 50px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px;
    grid-column-gap: 20px;
}

.banner .search input{
    width: 90%;
    height: 50px;
    padding: 5px;
    padding-left: 15px;
    border: none;
    background: rgb(249,249,249);
    border-radius: 30px;
}  

.banner .search div{
    background: rgb(249,249,249);
    border-radius: 30px;
    padding: 0px 10px;
    width: 40%;
}


.banner .search textarea:focus, input:focus{
    outline: none;
}

.banner .search input::placeholder{
    color: rgba(128, 128, 128, 0.596);
}

.banner .search button{
    width: 110px;
    height: 45px;
    background: rgb(239,63,62);
    color: white;
    border-radius: 30px;
    border: none;
    font-weight: bold;
    
}

.banner .search button:hover{
    background: white;
    color: rgb(239,63,62);
    box-shadow: 2px 2px 5px 1px rgba(194, 74, 74, 0.836);
}

.banner .search i{
    color:rgba(128, 128, 128, 0.596);
}
@media only screen and (max-width:991px){
    .banner {height: 500px;
    }
    .banner .search {margin-left: 25px;}
}
@media (max-width: 786px){

    .banner {height: 400px;}
    .banner .search{
        width: 80%;
        height: 90px;
     
    }

    .banner .search input{
        width: 90%;
        height: 40px;
        padding: 5px;
        font-size: 10px;
        padding-left: 15px;
    }

    .banner .search button{
        width: 110px;
        height: 35px;
        font-size: 10px;
        
    }

    .banner .chngImg{
        right: 0%;
    }

    .banner .chngImg li{
        font-size: 20px;
    }

    .search div i{
        display: none;
    }

    .journeyContain{
        display: none;
    }
    .eWjCzc {display: none;}

}


@media only screen and (max-width:570px) {
    .experience {margin-left: 0px !important; padding-left: 10px;
        padding-right: 10px;}
        .banner .search div {width: 100%;}
}