.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.eWjCzc:hover:enabled, .eWjCzc:focus:enabled {
  color: #fff;
  background-color: #dc3545 !important;
  box-shadow: 0 0 2px 0 #333;
}

.offerSection{
  margin-top: 130px;
  height: fit-content;
  background: rgb(255,255,255);
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}

.krmNah {
    background-color: red !important;
    box-shadow: 0 0 1px 3px rgb(183 58 58) !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (min-width:1024px){

  .strechSection{
    max-width: 1400px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
  }

  .strechContainer{
    display: flex;
    width:100%;
    flex-direction: column;
    align-items:center;
  }
}