.userdashboard{
    width: 100%;
    height: 210vh;
    background: #f9f9f9;
}

.userdashboard img{
    width: 100%;
    height: auto;
}



.section .sidebar{
    background: white;
    box-shadow: 0 2px 4px 0 #dedede;
    padding: 5px;
    width: 100%;
    height: 79vh;
    position: sticky;
    top: 0;
}

.section .sideContain{
    position: relative;
    width: 30%;
    height: 155vh;
}

.sidebar .col{
    width: 100%;
}

.profile-image{
    width: 200px;
    display: flex;
    justify-content: center;
    margin: 15px;
}

.profile-image img{
    width: 130px;
    border-radius: 100%;
}

.profile-top{
    background: #f9f9f9;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative;
}

.profile-top h5{
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: bold;
    letter-spacing: 0.03em;
    line-height: 1.2;
    text-transform: capitalize;
    text-align: center;
}

.profile-edit{
    position: absolute;
    right: 23%;
    top: 45%;
}

.profile-top h6{
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.03em;
}

.faq-tab .nav{
    display: flex;
    flex-direction: column;
}

.faq-tab .nav li a{
    color: black;
}

.faq-tab .nav li a:hover{
    color: #ef3f3e;
    border-right: 2px solid #ef3f3e;
}

.faq-tab .nav li a:focus{
    color: #ef3f3e;
    border-right: 2px solid #ef3f3e;
}


.section{
    display: flex;
    margin: 20px;
    position: relative;
}

.section .content{
    width: 100%;
    height: auto;
    margin-left: 20px;
    padding: 20px;
    background: white;
    box-shadow: 0 2px 4px 0 #dedede;
}

.section .content .completeProfile{
    background: #f9f9f9;
    padding: 10px;
    
}

.content .completeProfile p{
    color: rgba(0, 0, 0, 0.8);
    text-transform: capitalize;
    letter-spacing: 0.08em;
}

.content .completeProfile .buttonFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttonFlex i{
    color: green;
}

.buttonFlex .fa-window-close{
    color: red;
}


.content .completeProfile .button{
    background-color: white;
    padding: 15px;
    width: 100%;
    margin-bottom: 8px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(222 222 222 / 22%);
    text-align: center;

}

.counterContainer{
    margin-top: 20px;
    background: white;
}

.counterContainer .boxes{
    display: flex;
}

.section .counterContainer .boxes .counterBox{
    background: #f9f9f9;
    width: 50%;
    margin: 10px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.boxes .counterBox img{
    width: 35px;
}

.counterBox h3{
    margin-top: 10px;
}

.counterBox h5{
    color: rgba(0, 0, 0, 0.61);
    margin-bottom: -5px;
    text-transform: capitalize;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.lastSection{
    display: flex;
    justify-content: center;
    padding: 10px;
}

.lastSection .analytic{
    width: 50%;
    background: #f9f9f9;
    height: 320px;
    
}

.analytic .info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.analytic .info button{
    width: 130px;
    height: 20px;
    font-size: 10px;
    font-weight: bold;
    margin: 3px;
    border: none;
    color: white;
    transition: ease .2s;
}

.analytic .info button:hover{
    width: 135px;
}

.analytic .info .completed{
    background: rgba(250, 73, 98, 0.85);
}


.analytic .info .upcoming{
    background: rgba(55, 156, 249, 0.85);
}

.analytic .info .cancelled{
    background:rgba(162, 100, 255, 0.85);
}

.analytic .info .total{
    background:rgba(162, 100, 255, 0.85);
}

.analytic .percentage{
    text-align: center;
    margin-top: 30px;
}

.analytic .percentage .total h4{
    color: rgba(162, 100, 255, 0.85);
}

.analytic .percentage .completedperc h4{
    color: rgba(250, 73, 98, 0.85);
}

.analytic .percentage .completedperc{
    display: none;
}

.analytic .percentage .upcomingperc h4{
    color: rgba(55, 156, 249, 0.85);
}

.analytic .percentage .upcomingperc{
    display: none;
}

.analytic .percentage .cancelledperc h4{
    color: rgba(162, 100, 255, 0.85);
}

.analytic .percentage .cancelledperc{
    display: none;
}


.lastSection .recentActivity{
    width: 50%;
    height: 320px;
    background: #f9f9f9;
    margin-left: 15px;
    padding: 15px;
    display: flex;
    justify-content: center;

}

.recentActivity div{
    width: 100%;
  
}

.recentActivity div li{
    display: flex;
    justify-content: space-between;
    list-style: none;
    font-size: 14px;
    letter-spacing: 0.03em;
    margin-top: 10px;
    padding: 7px;
    text-transform: capitalize;
    background: white;
    border-left: 2px solid #ef3f3e;
}

.recentActivity div h6{
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: 0.03em;
    font-weight: bold;
}