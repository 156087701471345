.login .main {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 190px 0px;
  margin-bottom: 80px;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(251, 251, 253);
}

.login button {
  margin-left: 0px;
}

.login .main input {
  margin-top: 5px;
  border: 1px solid rgba(196, 193, 193, 0.685);
  padding: 5px;
}

.login .main input textarea:focus,
input:focus {
  outline: none;
}

.login .main label {
  margin-top: 15px;
  font-size: 13px;
  font-weight: bold;
}

.login .main .logbtn {
  background: rgb(239, 63, 62);
  color: white;
  border: 1px solid rgb(239, 63, 62);
  font-weight: bold;
  padding: 5px;
  transition: ease 0.3s;
  width: 100%;
  margin-top: 15px !important;
}

.login .main .logbtn:hover {
  background: white;
  color: rgb(239, 63, 62);
}

.login .main .createbtn {
  background: white;
  color: rgb(239, 63, 62);
  border: 1px solid rgb(239, 63, 62);
  font-weight: bold;
  padding: 5px;
  transition: ease 0.3s;
  margin-top: 10px;
}

.login .main .createbtn:hover {
  background: rgb(239, 63, 62);
  color: white;
}

.google1 {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.loginbtnContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  flex-wrap: wrap;
  align-items: center;
  align-items: center;
}

.loginbtnContainer button a {
  text-decoration: none;
  color: black;
}

.googlebtn {
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 0px;
  width: 30%;
  padding: 10px 0px;
  border: none;
}

.kep-login-facebook {
  display: flex;
  align-items: center;
  font-family: "Google Sans", arial, sans-serif !important;
  font-weight: 500 !important;
  text-transform: none !important;
  justify-content: space-evenly;
  width: fit-content !important;
  height: 30px !important;
  padding: 0px 20px !important;
  font-size: 13px !important;
  border: 1px solid #dadce0 !important;
}

.kep-login-facebook.metro {
  border-radius: 4px !important;
}

.fblogin i {
  margin-right: 5px;
}

.fblogin:hover {
  background: rgba(66, 133, 244, 0.04);
}

.react-apple-signin-auth-btn-dark {
  border-radius: 2px;
  color: black !important;
  font-size: 17px;
  background: white !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: fit-content;
  padding: 0px 20px;
  height: 30px;
  border: 1px solid #dadce0 !important;
  margin-top: 0px;
}

rect {
  fill: white;
}

.react-apple-signin-auth-btn path {
  fill: black;
}

.login .input-group {
  margin: auto !important;
}
.login h1 {
  text-align: center;
}

.login .text-medium-emphasis {
  text-align: center;
  margin-bottom: 25px;
}

.login .input-group-text {
  background-color: #fff;
  border-radius: 0px;
}

.login .input-group-text path {
  fill: #000 !important;
}
.login .col-6 {
  width: 100%;
  margin: auto;
  text-align: center;
}

.login .card-group {
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
}
.ad-log {
  flex: auto !important;
  width: 50% !important;
  margin: auto;
  position: relative;
  z-index: 9999;
  overflow: hidden;
}

.login {
  width: 100%;
  position: relative;
}

.login .card-group {
  width: 70% !important;
  position: relative;
  margin: auto;
  overflow: hidden;
  z-index: 999;
}

#root .table tbody path {
  fill: #000;
}
.needs-validation {
  width: 100%;
  background-color: #fff;
  padding: 40px;

  border-radius: 15px;
}

.ed-services {
  width: 100% !important;
}

.needs-validation .form-label {
  font-weight: bold;
}

.needs-validation .form-select {
  border-radius: 0px;
}

.needs-validation .form-control {
  border-radius: 0px;
  border: 1px solid #ced4da !important;
}
.needs-validation .btn-primary {
  border: none;
  background-color: #3c4b64;
}

.needs-validation textarea {
  resize: none;
}

.needs-validation .react-datetime-picker__wrapper {
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.needs-validation option {
  border-radius: 0px !important;
}

.customePagination {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
}

.customePagination li {
  padding: 8px 0px !important;
}

.customePagination a {
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-calendar-day {
  left: 68% !important;
}

.customePagination a:hover {
  color: #34a853;
}

.customePagination .selected {
  background-color: #34a853;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.needs-validation div:first-child {
  font-weight: bold;
}
.cb-price {
  align-items: center;
  grid-column-gap: 127px;
  /* width: 50% !important; */
}

.cb-available-date {
  align-items: center;
  grid-column-gap: 49px;
  /* width: 50% !important; */
}

.cb-start-date .react-datetime-picker {
  width: 100% !important;
}
.cb-end-date .react-datetime-picker {
  width: 100% !important;
}

.cb-available-date .react-datetime-picker {
  width: 100% !important;
}

.cb-start-date {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 15px;
}

.cb-end-date {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 15px;
}

.cb-title {
  width: 100% !important;

  align-items: center;
  grid-column-gap: 132px;
}

.cb-title .form-control {
  width: 100%;
}

.cb-description {
  width: 100% !important;

  align-items: center;
  grid-column-gap: 70px;
}

.cb-description .form-control {
  width: 100%;
}

.cb-days {
  align-items: center;
  grid-column-gap: 83px;
  /* width: 50% !important; */
}

.cb-days .form-control {
  width: 100%;
}
.form-control {
  width: 100% !important;
}

.cb-activities {
  align-items: center;
  grid-column-gap: 15px;
  /* width: 50% !important; */
}
.cb-activities .form-control {
  width: 100%;
}
#root .body .container:first-child {
  padding-top: 10px !important;
  padding: 5px !important;
}

.preview {
  border-radius: 50px !important;
  background: rgb(48, 60, 84) !important;
  border: 0px !important;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 25%) !important;
}
.cb-status {
  align-items: center;
  grid-column-gap: 15px;
  /* width: 50% !important; */
}

.cb-submit {
  width: 100% !important;
  text-align: center;
  margin: auto;
  margin-top: 20px !important;
}

.cd-submit {
  width: 100% !important;
  text-align: center;
  margin: auto;
  margin-top: 20px !important;
}

.edc-submit {
  width: 100% !important;
  text-align: center;
  margin: auto;
  margin-top: 20px !important;
}

.cd-services {
  width: 100% !important;
}

.ed-submit {
  width: 100% !important;
  text-align: center;
  margin: auto;
  margin-top: 20px !important;
}

.ed-category {
  /* width: 25% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.ed-country {
  /* width: 25% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.ed-title {
  width: 25% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.ed-price {
  width: 25% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.ed-image {
  align-items: center;
  grid-column-gap: 10px;
  width: 75% !important;
}

.ed-image-preview {
  width: 25% !important;
}

.ed-image-preview img {
  float: right;
}

.ed-offer-price {
  align-items: center;
  grid-column-gap: 10px;
  width: 50% !important;
}

.ed-sort-description {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

/* .ed-sort-description .form-control {
  width: 60%;
} */

.ed-long-description {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

/* .ed-long-description .form-control {
  width: 60%;
} */

.ed-person {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
}
/* .ed-person .form-control {
  width: 40%;
} */

.ed-days {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
}
/* .ed-days .form-control {
  width: 40%;
} */

.ed-nights {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
}

/* .ed-nights  .form-control {
  width: 40%;
} */

/* .ed-meta-title {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
} */

/* .ed-meta-description {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
} */

/* .ed-meta-keyboard {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
} */

/* .ed-meta-description textarea {
  max-height: 40px !important;
} */

.needs-validation .row {
  padding-bottom: 10px;
}

.cd-category {
  /* width: 25% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-country {
  /* width: 25% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-title {
  width: 25% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cd-price {
  /* width: 25% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-image {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-offer-price {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-sort-description {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-long-description {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-person {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-days {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cd-nights {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

/* .cd-meta-title {
  width: 33.33% !important;
  align-items: center;
  grid-column-gap: 10px;
} */

/* .cd-meta-description {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
} */

/* .cd-meta-keyboard {
  width: 33.33% !important;

  align-items: center;
  grid-column-gap: 10px;
} */

.edc-title {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}
.edc-status {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cdc-title {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cdc-status {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cdc-image {
  width: 100% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cdc-submit {
  margin: auto;
  text-align: center;
}
.edi-title {
  width: 100% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.edi-image {
  width: 75% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.edi-image-preview {
  float: right;
  width: 25% !important;
}

.edi-image-preview img {
  float: right;
}

.edi-submit {
  margin: auto;
  text-align: center;
  margin-top: 20px !important;
}

.cdi-destination {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cdi-title {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cdi-display-order {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cdi-image {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cdi-status {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.cdi-submit {
  margin: auto;
  text-align: center;
}

.eds-name {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.eds-status {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.eds-submit {
  margin: auto;
  text-align: center;
}

.cds-name {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cds-stats {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.cds-submit {
  margin: auto;
  text-align: center;
}

.eb-destination {
  width: 50% !important;

  align-items: center;
  grid-column-gap: 10px;
}

.eb-price {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-available-date {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-start-date {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-available-date .react-datetime-picker {
  width: 100%;
}

.eb-start-date .react-datetime-picker {
  width: 100%;
}

.eb-end-date {
  /* width: 69% !important; */

  align-items: center;
  grid-column-gap: 10px;
  /* margin-top: 20px !important; */
}

.eb-end-date .react-datetime-picker {
  width: 72%;
}

.eb-title {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-description {
  /* width: 50% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-days {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-activities {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}

.eb-status {
  /* width: 33.33% !important; */

  align-items: center;
  grid-column-gap: 10px;
}
.eb-submit {
  margin: auto;
  text-align: center;
}
.list-icon {
  grid-column-gap: 8px;
  align-items: center;
}
.table {
  background-color: #fff !important;
  margin-left: 10px;
  margin-right: 10px;
}

.cattegory {
  margin-left: 8px;
}
.supplier-table {
  width: 99% !important;
  margin: auto;
  margin-top: 5px;
}

.react-confirm-alert-body h1 {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.react-confirm-alert-button-group button {
  background-color: #ea4335 !important;
  color: #fff !important;
}

.noDataFound {
  margin: auto;
  width: 98% !important;
}

.booking-date {
  padding-bottom: 9px;
  font-size: 18px;
}
.cb-t {
  width: 100% !important;
}
.cb-mt {
  width: 33.33% !important;
}

.copan-name {
  /* width: 33.33% !important; */
}

.copan-date {
  /* width: 50% !important; */
}

.copan-date .react-datetime-picker {
  width: 100%;
}

.copan-total {
  /* width: 50% !important; */
}

.copan-bt {
  margin: auto;
  text-align: center;
}

.gift-price {
  width: 50% !important;
}
.gift-bt {
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .gift-price {
    width: 100% !important;
  }

  .copan-total {
    width: 100% !important;
  }

  .copan-date {
    width: 100% !important;
  }

  .cb-mt {
    width: 100% !important;
  }

  .copan-name {
    width: 100% !important;
  }

  .eb-price {
    width: 100% !important;
    margin-top: 15px !important;
  }

  .eb-end-date {
    width: 100% !important;
  }
  .eb-end-date .react-datetime-picker {
    width: 100%;
  }
  .eb-title {
    width: 100% !important;
  }
  .eb-description {
    width: 100% !important;
  }
  .eb-days {
    width: 100% !important;
  }
  .eb-activities {
    width: 100% !important;
  }

  .eb-status {
    width: 100% !important;
  }

  .eb-available-date {
    width: 100% !important;
  }

  .eb-start-date {
    /* width: 100% !important; */
    margin-top: 15px !important;
    margin-bottom: 15px;
  }
  .eb-destination {
    width: 100% !important;
  }
  .ed-category {
    width: 100% !important ;
  }
  .ed-country {
    width: 100% !important ;
  }
  .ed-title {
    width: 100% !important ;
  }
  .ed-price {
    width: 100% !important ;
  }
  .ed-image .form-control {
    width: 100%;
  }
  .ed-image {
    width: 100% !important;
  }
  .ed-image-preview {
    width: 100% !important;
    margin: auto;
    text-align: center;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .ed-image-preview img {
    margin: auto;
    float: none;
  }
  .ed-offer-price {
    width: 100% !important;
  }
  .ed-offer-price .form-control {
    width: 100%;
  }

  .ed-sort-description {
    width: 100% !important;
  }
  .ed-long-description {
    width: 100% !important;
  }
  .ed-person {
    width: 100% !important;
  }
  .ed-days {
    width: 100% !important;
  }
  .ed-nights {
    width: 100% !important;
  }

  .ed-meta-title {
    width: 100% !important;
  }
  .ed-meta-description {
    width: 100% !important;
  }
  .ed-meta-keyboard {
    width: 100% !important;
  }
  .needs-validation {
    margin-bottom: 50px;
    padding: 10px;
  }
  .login .input-group {
    width: 100% !important;
  }

  .cd-category {
    width: 100% !important;
  }
  .cd-country {
    width: 100% !important;
  }
  .cd-title {
    width: 100% !important;
  }
  .cd-price {
    width: 100% !important;
  }
  .cd-image {
    width: 100% !important;
  }
  .cd-offer-price {
    width: 100% !important;
  }
  .cd-sort-description {
    width: 100% !important;
  }
  .cd-long-description {
    width: 100% !important;
  }
  .cd-person {
    width: 100% !important;
  }
  .cd-days {
    width: 100% !important;
  }
  .cd-nights {
    width: 100% !important;
  }
  .cd-meta-title {
    width: 100% !important;
  }
  .cd-meta-description {
    width: 100% !important;
  }
  .cd-meta-keyboard {
    width: 100% !important;
  }
  .edc-title {
    width: 100% !important;
  }
  .edc-status {
    width: 100% !important;
  }
  .needs-validation .row {
    display: block;
  }

  .cdc-title {
    width: 100% !important;
  }
  .cdc-status {
    width: 100% !important;
  }
  .edi-image {
    width: 100% !important;
  }
  .edi-image-preview {
    width: 100% !important;
    float: none;
    text-align: center;
  }
  .edi-image-preview img {
    float: none;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }

  .cdi-destination {
    width: 100% !important;
  }
  .cdi-title {
    width: 100% !important;
  }
  .cdi-display-order {
    width: 100% !important;
  }
  .cdi-image {
    width: 100% !important;
  }
  .cdi-status {
    width: 100% !important;
  }

  .eds-name {
    width: 100% !important;
  }
  .eds-status {
    width: 100% !important;
  }
  .cds-name {
    width: 100% !important;
  }
  .cds-stats {
    width: 100% !important;
  }
}

.react-datetime-picker__inputGroup input {
  font-weight: normal;
}

.react-datetime-picker__inputGroup__leadingZero {
  font-weight: normal;
}
.react-datetime-picker__inputGroup__divider {
  font-weight: normal;
}
select {
  font-weight: normal !important;
}

abbr[title] {
  text-decoration: none !important;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
}

.sidebar-nav .nav-group-items .nav-link {
  padding-left: 25px !important;
}

.applebtn img {
  margin-right: 5px;
}

.react-apple-signin-auth-btn-dark:hover {
  background: rgba(66, 133, 244, 0.04) !important;
}

.applebtn img {
  width: 22px;
}

.signupbox {
  background: grey;
  margin-top: -250px !important;
  width: 150px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  opacity: 0.3;
  margin: auto;
}

.orContainer {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid rgba(196, 193, 193, 0.685);
  position: relative;
  margin: 30px 0px;
}

.or {
  font-weight: bold;
  background: white;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 5px;
  position: absolute;
  bottom: -21px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.inputContainer p {
  color: red;
}

.rememberLabel {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}

@media (min-width: 1260px) {
  .applebtn {
    margin-top: auto;
  }

  .fblogin {
    margin-top: auto;
  }
}

@media (max-width: 1015px) {
  .kep-login-facebook {
    margin-top: 8px !important;
  }
}

@media (max-width: 900px) {
  .loginbtnContainer {
    flex-direction: column;
    align-items: center;
  }

  .fblogin {
    margin-top: 8px;
  }
}

@media (max-width: 500px) {
  .login .main {
    width: 47%;
  }

  .loginbtnContainer {
    flex-direction: column;
    align-items: start;
  }
}

.react-tagsinput-tag {
  background-color: #d8dbdf !important;
  border-radius: 0 !important;
  border: none !important;
  color: rgba(44, 56, 74, 0.95) !important;
  display: inline-block;
  font-family: inherit !important;
  font-size: 1rem !important;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: rgba(44, 56, 74, 0.95) !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100% !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.react-tagsinput--focused {
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.react-tagsinput-tag a::before {
  content: " ×";
  color: rgb(49, 49, 49);
  font-size: 1.2rem;
  padding: 0 5px !important;
  font-weight: bold;
}

.react-tagsinput-remove {
  text-decoration: none !important;
}

.title-helper {
  font-size: 10px;
  font-style: italic;
  color: #777;
}

.dashboard-heading {
  padding: 50px 0 10px 10px;
}

.no-booking {
  text-align: center;
  padding: 10px;
}

.no-destination {
  text-align: center;
  padding-top: 50px;
}
