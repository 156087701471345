.add-padding {
  padding: 10px 0;
}

.add-padding .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  background-color: rgba(0, 0, 0, 0.12) !important;
}


.supplier-content .css-1ujnqem-MuiTabs-root {background-color: #Ffff; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 15%);padding: 15px;}
.supplier-content .css-1aquho2-MuiTabs-indicator {height: 0px !important;}

.supplier-content .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {background-color: #ef3f3e !important; color: #fff !important; border-radius:0px !important ;}
.supplier-content   .MuiTab-textColorPrimary {background-color: #f9f9f9 !important; color: #000 !important; border-radius:0px !important ;}
.supplier-content .MuiTabs-flexContainer {grid-column-gap: 15px;}

.slect-bt .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {color: #000; background-color: #f9f9f9; border: 0px;  padding-right: 21px;
  padding-top: 12px;
  padding-bottom: 11px;
  border-radius: 50px !important; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);display: block;
  text-align: center;
  margin: auto;padding-left: 20px; }
.slect-bt {margin-top: 15px; padding-left: 20px;
}
.slect-text {text-align: center; font-weight: bold;}

.slect-bt .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover{border: 0px; background-color: #ef3f3e; color: #fff;}
.supplier-content .css-9mc7sf-MuiInputAdornment-root.MuiInputAdornment-positionStart.css-9mc7sf-MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel) {margin-top: 0px;}

.supplier-content  .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input {padding-top: 0px; padding-bottom: 0px;}
.supplier-content  .css-1xab92c-MuiInputBase-root-MuiFilledInput-root {background-color: transparent; border: 1px solid rgba(196, 193, 193, 0.685); padding-top: 10px;padding-bottom: 10px; border-radius: 0px;}
.supplier-content .css-9mc7sf-MuiInputAdornment-root {color: #000;}

.supplier-content .css-1xab92c-MuiInputBase-root-MuiFilledInput-root:before {border-bottom: 0px ;}

.add-website {font-weight: bold; margin-bottom: 10px;}

.add  {display: flex; grid-column-gap: 15px;}

.supplier-content .css-10botns-MuiInputBase-input-MuiFilledInput-input {padding-top: 0px; padding-bottom: 0px; }


.supplier-content  .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root {background-color: transparent; border: 1px solid rgba(196, 193, 193, 0.685); padding-top: 5px;padding-bottom: 5px; border-radius: 0px;}



.supplier-content  .css-19mk8g1-MuiInputBase-root-MuiFilledInput-root:before {border: 0px;}