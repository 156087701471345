.emailVerify{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emailVerify h1{
    font-weight: bold;
    margin-top: 20px;
}

.emailVerify .success button{
    width: 150px;
    padding: 10px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: rgb(239,63,62);
    border: 2px solid rgb(239,63,62);
}
.emailVerify .success button:hover{background: #4285f4;}

.emailVerify .failure button:hover{
    background-color: white;
    color: rgb(239,63,62);
}

.emailVerify .failure button{
    width: 150px;
    padding: 10px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background: rgb(255, 144, 18);
    border: 2px solid rgb(255, 144, 18);
}

.emailVerify .failure button:hover{
    background-color: white;
    color: rgb(255, 144, 18);
}

.emailVerify .success{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emailVerify .failure{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
