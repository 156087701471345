.errorPage{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.errorPage button{
    width: 150px;
    padding: 10px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background: rgb(255, 144, 18);
    border: 2px solid rgb(255, 144, 18);
}


.errorPage button:hover{
    background-color: white;
    color: rgb(239,63,62);
}