@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.destinationsList{
    width: 100%;
}

.destinationsList img{
    width: 100%;
    height: 60vh;
    background-attachment: fixed;
}

section{
    background: #f9f9f9;
    height: auto;
    padding: 20px;
}



.filterPanel .leftPanel ul li{
    list-style: none;
    margin-left: 10px;
    background: #f9f9f9;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    width: 15%;
    padding-left: 5%;
}

.filterPanel .leftPanel ul li:hover{
    background: #ef3f3e;
    color: white;
}

.filterPanel{
    display: flex;
    background: white;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

}

.filterPanel .leftPanel{
    width: 100%;
}


.filterPanel .leftPanel ul{
    display: flex;
    justify-content: space-evenly;
    margin: 0;
    padding-left: 0px;
}

.filterPanel .leftPanel ul li:focus{
    background-color: red;
}

.filterPanel .rightPanel div{
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
}

.mainSection{
    display: flex;
    justify-content: space-around;
    height: fit-content;
    margin-top: 20px;
    position: relative;
}

.mainSection .sidebar{
    background-color: white;
    width: 25%;
    height: fit-content;
    padding: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainSection .sidebar .searchbar{
    background: #f9f9f9;
    width: 70%;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainSection .sidebar .searchbar input{
    width: 60%;
    border: none;
    margin-left: 5px;
    background: none;
}

.mainSection .sidebar .searchbar i{
    color: #ef3f3e;
}

.sidebar .back2{
    display: none;
}


.latestFilter{
    background: white;
    width: 100%;
    margin-top: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    display: none;
}

.latestFilter h5{
    font-weight: bold;
    margin-bottom: 0px;
}

.latestFilter img{
    width: 18px;
    height: 13px;
    cursor: pointer;
}

.sidebar .filterByRating{
    width: 100%;
    display: flex;
    flex-direction: column;
   
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1.5px solid #f9f9f9;
    align-items: baseline;
}

.sidebar .filterByRating .sortBy{
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    border-bottom: 1.5px solid #f9f9f9;
}

.sidebar .filterByRating .sortBy h6{
    font-weight: 700;
}

.sidebar .filterByRating .sortBy select{
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    font-weight: 700;
}

.sidebar .filterByRating .sortBy select option{
    background-color: white;
    color: black;
    padding: 5px;
}

.sidebar .filterByRating .sortBy select:hover{
    background: #ef3f3e;
    color: white;
    border: #ef3f3e;
}

.sidebar .filterByRating input[type="checkbox"]{
    box-shadow: none;
    border-color: #a3a2a2c4;
    cursor: pointer;
    font-size: 18px;
    border-radius: 0px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")!important;
}


.sidebar .filterByRating  input[type="checkbox"]:enabled:checked{
    background: #ef3f3e;
    color: white !important;
}

.sidebar .filterByRating #Content4 input[type="range"]{
    accent-color: #F55050;
}

.sidebar .filterByRating #Content4 .stars{
    display:"flex";
    align-items:"center";
}



.sidebar .filterByRating .Title{
    display: flex;
    width: 80%;
    justify-content: space-between;
    
}


.sidebar .filterByRating .Title h6{
    font-weight: bold;
    text-transform: capitalize;
    margin-left: 15px;
}

.sidebar .filterByRating .Title i{
    background: #f9f9f9;
    width: 20px;
    padding: 3px;
    font-size: 15px;
    cursor: pointer;
}

.filterByRating .Title .fa-plus{
    display: none;
}

.sidebar .filterByRating .Content .stars{
    margin: 10px;
    display: flex;
    align-items: center;
}



.sidebar .filterByRating .Content .stars span{
    cursor: pointer;
}

.sidebar .filterByRating .Content .stars i{
    margin-left: 5px;
    font-size: 12px;
    color: gold;
}

.sidebar .filterByRating .Content .stars label{
    margin-left: 7px;
}

.sidebar .filterByRating .Content .stars span{
    color: #9a9a9a;
    font-size: 15px;
    text-transform: capitalize;
}

.form-check-input[type=radio]{
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #fd0d0d;
    border-color: #fd0d0d;
}

.sidebar .filter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-top: 10px;
    border-top: 1.5px solid #f9f9f9;
    padding-top: 10px;
}

.sidebar .filter h5{
    font-size: 18px;
    font-weight: bold;
}

.sidebar .filter img{
    width: 15px;
    height: 10px;
    cursor: pointer;
}


.mainSection .destinations{
    width: 80%;
    height: fit-content;
    margin-left: 20px;
    margin-bottom: 15px;
}


.mainSection .destinations .errorContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.mainSection .destinations .errorContainer .error{
    font-weight: bold;
}

.destinations .itemsContainer{
    display: flex;
    flex-wrap: wrap;
}

.destinations .itemsContainer .item{
    background: white;
    width: 48%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
    margin: 5px;
    position: relative;
}

.destinations .itemsContainer .item .fa-heart{
    position: absolute;
    top: 3%;
    right: 5%;
    font-size: 25px;
    color: white;
    z-index: 100;
    cursor: pointer;
    filter: drop-shadow(2px 4px 6px black);
}

.destinations .itemsContainer .item:hover img{
    transform: scale(1.1);
}

.destinations .itemsContainer .item img{
    width: 100%;
    height: 200px;
    transition: .5s ease;
    cursor: pointer;
    
}

.itemsContainer .item .content{
    margin: 15px;
}

.itemsContainer .item .content h6 span{
   cursor: pointer;
   margin-left: 5px;
   color: black;
    font-weight: 700;
}

.itemsContainer .item .content h6 span:hover{
    color: #ef3f3e;
 }

.itemsContainer .item .content a{
    text-decoration: none;
    color: black;
    font-weight: 700;
}

.itemsContainer .item .content h5:hover{
    color: #ef3f3e;
}

.itemsContainer .item .content .ratingFlex{
    display: flex;
    flex-direction: column;
}

.item .content .ratingFlex .rating{
    display: flex;
    margin-bottom: 5px;
}

.item .content .ratingFlex .rating i{
    font-size: 13px;
    color: gold;
    margin-left: 5px;
}

.item .content .ratingFlex .rating i:first-child{
    margin-left: 0px;
}

.item .content h5{
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: capitalize;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
}


.item .content span{
    color: #9a9a9a;
    font-size: 14px;
}


.item .content h6{
    margin-top: 4px;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.78);
    text-overflow: ellipsis;
    font-size: 13px;
}

.item .content h4{
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    color: #ef3f3e;
    text-transform: capitalize;
}

.item .content .benefits{
    list-style: none;
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.item .content .benefits li i{
    color: rgba(124, 121, 121, 0.78);
}

.item .content .price h6{
    color: #ef3f3e;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 800;
    font-family: 'Nunito', sans-serif;
}

.item .content .price del{
    font-size: 14px;
    color: black;
    font-weight: 700;
    margin-right: 10px;
}

.item .content .price span{
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.57);
    text-transform: capitalize;
}

.mainSection .pagination{
    position: absolute;
    bottom: 0;
}

.searchBar{
    background: white;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


.searchBar .searchInput{
    background: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    padding-right: 20px;
    width: 100%;
}

.searchBar .searchInput i{
    font-size: 20px;
    color: #ef3f3e;
}

.searchBar input{
    background-color: #f9f9f9;
    border: none;
    width: 90%;
    padding: 15px 15px 15px;
    line-height: 1;
    font-weight: 700;
}

.searchBar button{
    background-color: #ef3f3e;
    border: 1px solid #ef3f3e;
    color: white;
    width: 100px;
    padding: 5px;
    font-weight: bold;
    box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 12%);
    transition: ease .5s;
}

.searchBar button:hover{
    background-color: white;
    color: #ef3f3e;
}


.paginationBttns {
    width: 100%;
    height: 30px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    text-decoration: none;
    border: 1px solid #ef3f3e;
    color: #ef3f3e !important;
    background-color: white;
    cursor: pointer;
    font-weight: bold;
  }
  
  .paginationBttns a:hover {
    color: white !important;
    background-color: #ef3f3e;
  }
  
  .paginationActive a {
    color: white !important;
    background-color: #ef3f3e;
  }
  
  .paginationDisabled a {
    color: white !important;
    background-color: rgba(0, 0, 0, 0.57);
    border: none;
  }

  .paginationDisabled a:hover{
    background: rgba(0, 0, 0, 0.57);
  }


  .ant-slider{
    width: 150px;
  }

  .ant-slider-track{
    background: #ef3f3e !important;
  }

  .ant-slider-handle{
    border: solid 2px #ff9191 !important;
  }

  .Content .star input[type=radio]{
    cursor: pointer;
  }

@media (max-width:980px){

    .mainSection .sidebar .searchbar{
        background: white;
    }

    .mainSection .sidebar{
        height: 100vh;
        width: 30%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 100;
        overflow-y:scroll;
        overflow-x:hidden;
        display: none;
        animation: side-animation ease-in-out .5s;
    }

    @keyframes side-animation {
        from{
            width: 15%;
        }
        to{
            width: 25%;
        }
    }

    .sidebar .filterByRating{
        animation: stars ease-in-out 1s;
    }

    @keyframes stars {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }

  
    .mainSection .sidebar .searchbar .searchHide{
        display: none;
    }

    .sidebar .back2{
        display: block;
        font-weight: bold;
        cursor: pointer;
        margin-left: 20%;
    }

    .mainSection .sidebar .filter{
        display: none;
        margin-top: 30px;
    }

    .latestFilter{
        display: flex;
    }
}



@media (max-width:800px){
    .mainSection .sidebar{
        width: 50%;
    }

    @keyframes side-animation {
        from{
            width: 25%;
        }
        to{
            width: 55%;
        }
    }

    .sidebar .filterByRating{
        animation: stars ease-in-out 1s;
    }

    @keyframes stars {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }

    
}

@media (max-width:600px){
    .destinations .itemsContainer{
        flex-direction: column;
    }

    .destinations .itemsContainer .item{
        width: 80%;
    }
}

@media (max-width:500px){

    .mainSection .sidebar{
        width: 65%;
    }

    @keyframes side-animation {
        from{
            width: 30%;
        }
        to{
            width: 60%;
        }
    }

    .sidebar .filterByRating{
        animation: stars ease-in-out 1s;
    }

    @keyframes stars {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }

    .filterPanel{
        padding: 7px;
    }

    .leftPanel ul li{
        font-size: 12px;
    }

    .filterPanel .rightPanel div{
        font-size: 12px;
        padding: 3px;
        width: max-content;
    }

    .filterPanel .rightPanel div i{
        font-size: 10px;
    }
}