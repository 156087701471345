.gift-header {
  background-color: tomato;
  text-align: center;
  color: #fff;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}
.gift-heading {
  font-size: 40px;
}
.container {
  padding: 50px 0;
  width: 50%;
 
}

.gift-image {
  /* display: flex; */
  justify-content: center;
  padding: 30px 0;
}

.gift-is-suggested {
  display: flex;
  justify-content: space-between;
}

.gift-submit {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}







/* new css */
.whis-tab {background-color: #fff; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%); padding: 15px;}
.whis-tab .css-1aquho2-MuiTabs-indicator {height: 0px !important;}
.whis-tab .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {background-color: #ef3f3e !important; color: #fff !important; border-radius:0px !important ;}
.whis-tab   .MuiTab-textColorPrimary {background-color: #f9f9f9 !important; color: #000 !important; border-radius:0px !important ;}
.whis-tab .MuiTabs-flexContainer {grid-column-gap: 15px;}

.error-text {color: #ef3f3e;}
.wishlist-2 {width: 100%; margin-top: 150px;}
.cart-img {max-width: 900px; margin: auto; }
.emty-new {max-width: 900px; margin: auto;  border-radius: 5px; margin-bottom: 50px; padding-bottom: 20px;box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 15%); margin-top: 20px;}
.cart-img img {display: block; text-align: center; margin: auto;  }
.css-1aquho2-MuiTabs-indicator {height: 2px !important;}
.MuiButtonBase-root { border-radius: 20px !important;}
.cart-heading {text-align: center; color: #000; font-size: 25px; font-weight: 600; padding-top: 15px;}
.cart-subheading {text-align: center; color: #000; font-size: 16px; padding-top: 15px;}
.bt-emty  button {display: block; text-align: center; margin: auto;  margin: auto !important;
  display: block;
  text-align: center;
  border-radius: 20px;
   padding: 8px 14px 7px 14px;
   background-color: #f9f9f9;
   border: 0px;
  color: #000;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 15%);
}

  .css-sghohy-MuiButtonBase-root-MuiButton-root {background-color: #ef3f3e!important; } 

  .bt-emty  button:hover{ background-color: rgb(239, 63, 62); color: #fff;}
  .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {transition: 0.3s ease-in-out;
    background-color: white !important;
    color: #ef3f3e;
    
    -webkit-box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 22%);
    box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 22%);}

   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {color: #1b1b1b !important;}
   .css-1aquho2-MuiTabs-indicator { background-color: #1b1b1b !important;}
   .css-1gg0z0k-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {background-color: rgb(239, 63, 62) !important;}
.css-1gg0z0k-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  font-weight: 600;
    color: #000 ;
}
.css-13xfq8m-MuiTabPanel-root
 {padding: 0px; padding-top: 50px;}
.css-uym98a-MuiImageList-root
 {
  width: 100% !important;
  height: auto !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 15px !important;
  overflow-y:  hidden !important;
  
}


.wishlist-2  .css-13xfq8m-MuiTabPanel-root {padding: 0px !important; margin-top: 25px;}

.MuiImageListItem-root {
  padding: 15px;
  border: solid 2px #dbdbdb;
  border-radius: 5px;
}

.MuiBox-root  h3 {
  text-align: center;
}

.MuiBox-root  h6 {
  text-align: center;
}

.css-i4bv87-MuiSvgIcon-root {

 
    border-radius: 5px;
   
}

.emt-bt {display: block; text-align: center; margin: auto; border: 0px; border-radius: 20px; background-color: #f9f9f9; color: #000;padding-left: 15px;
  padding-right: 15px;
  padding-top: 14px;
  padding-bottom: 15px;
margin-top: 10px;
box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 15%);
}
 

  .emt-bt:hover{ background-color: rgb(239, 63, 62); color: #fff;}

  @media only screen and (max-width:991px){
    .cart-img img {width: 100%;}
    .cart-title {
      height: 24vh !important;
  }
  }