.user-footer{
   
    width: 100%;
    background:  #1b1b1b;
    color: white;
    
}

.user-footer .footerContainer{
   
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: space-between;
    position: relative;
}

.user-footer .first{
    width: 23%;
   
}

.user-footer .first span{
    color: #a1a1a1;
}

.user-footer .first p{
    color: #a1a1a1;
}
.fa-brands {color: #717171;}
.user-footer .first p i{
    font-size:13px;
    margin-right:12px;
}

.user-footer .first h1{
    font-size:33px;
    margin-bottom:40px;
    color: white;
}


.user-footer .second{
    margin-left: 5px;
    height: 270px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 380px;
}

.user-footer .second a{
    text-decoration: none;
}


.user-footer .second h5{
    font-size: 18px;
    color: white;

    margin-bottom: 40px;
}


.user-footer .second li{
    list-style: none;
    color: rgba(199, 199, 199, 0.801);
    line-height: 33px;
    cursor: pointer;
}

.user-footer .second li:hover{
    color: white;
}

.user-footer .topPlaces img{
    width: 105px;
    height: 100px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
    transition: ease .3s;
}

.user-footer .topPlaces img:hover{
    padding: 7px;
}

.user-footer .topPlaces h5{
    color: white;
    margin-bottom: 40px;
}
.copyContainer  .accounts {grid-column-gap: 35px; display: flex;}

.user-footer .accounts i{
   
    font-size: 25px;
    cursor: pointer;
}

.user-footer .copyContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1e1e1e;
    padding-bottom: 20px;
   
}

.user-footer .copyright h5{
    font-size: 18px;
    color: #717171;
}
.fa-brands:hover{
    color: rgba(255, 255, 255, 0.5);
}




.topPlaces img{
    margin-top: 10px;
}


@media (min-width:1165px){
    .topPlaces{
        width: 24%;
        margin-right: -90px;
    }
}


@media (max-width: 786px){


    .topPlaces{
        display: none;
    }
    .user-footer .first {width: 100% !important;}
    .second {display: flex!important; width: 100% !important; margin-left: 23px !important; justify-content: space-between; }
    .user-footer .footerContainer {display: block !important;  width: 100% !important; overflow: hidden; padding-left: 15px;
        padding-right: 15px; padding-top: 50px;
        padding-bottom: 50px;}
  
    
}

@media (max-width: 540px){
    



    .user-footer .footerContainer .second{
      
      
        margin-left: 20px;
    }

    .user-footer .copyContainer{
        flex-direction: column;
        padding-bottom: 40px;
        grid-row-gap: 15px;
    }

    .user-footer .accounts i{
        font-size: 15px;
    }

    .user-footer .copyright h5{
        font-size: 15px;
    }
}