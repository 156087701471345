.login .main{
    height: fit-content !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0px;
}

.login{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(251,251,253);
}

.login button{
  margin-left: 0px;
}

.login .main input{
    margin-top: 5px;
    border: 1px solid rgba(196, 193, 193, 0.685);
    padding: 5px;
}

.login .main .loginCenter{
  display: flex;
  justify-content: center;
}

.login .main .loginCenter p{
  font-weight: 700;
}

.login .main input textarea:focus, input:focus{
    outline: none;
}

.login .main label{
    margin-top: 15px;
    font-size: 13px;
    font-weight: bold;
}

.login .main .logbtn{
    background: rgb(239,63,62);
    color: white;
    border: 1px solid rgb(239,63,62);
    font-weight: bold;
    padding: 5px;
    transition: ease .3s;
}

.login .main .logbtn:hover{
    background: white;
    color: rgb(239,63,62);
    
}

.login .main .createbtn{
    background: white;
    color: rgb(239,63,62);
    border: 1px solid rgb(239,63,62);
    font-weight: bold;
    padding: 5px;
    margin-top:10px;
    transition: ease .3s;
}

.login .main .createbtn:hover{
    background: rgb(239,63,62);
    color: white;
    
}

.loginbtnContainer{
  display:flex;
  justify-content:space-around; 
  margin-bottom:15px;
  flex-wrap: wrap;
}

.google1 {
    background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .fblogin{
    border-radius: 2px;
    color: black;
    background:white;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    width:fit-content;
    padding: 0px 20px;
    height: 20px;
    border: 1px solid #dadce0;
  }

  .fblogin:hover{
    background:rgba(66,133,244,.04)
  }


  .applebtn{
    color: black;
    font-size: 17px;
    background:white;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    width:fit-content;
    padding: 0px 20px;
    height: 20px;
    border: 1px solid #dadce0;
    margin-top: 10px;
  }

  .applebtn img{
    margin-right: 5px;
  }

  .applebtn:hover{
    background:rgba(66,133,244,.04)
  }


  .applebtn img{
    width: 22px;
  }

  .loginbox{
    background:grey;
   
 
  margin-top: -250px !important;
    width:150px;
    height:120px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    color:white;
    opacity:0.3;
    margin: auto;
  }

  .orContainer{
    display:flex;
    justify-content:center;
    border-bottom:2px solid rgba(196, 193, 193, 0.685);
    position:relative;
    margin:30px 0px;
  }

  .or{
    font-weight:bold;
    background:white;
    width:40px;
    box-shadow:rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding:5px;
    position:absolute;
    bottom:-21px;
  }

  .inputContainer{
    display:flex;
    flex-direction:column;
    position: relative;
    margin-top:10px;
  }

  .inputContainer input[type=checkbox]{
    cursor: pointer;
  }

  .inputContainer .forgotContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .inputContainer .forgotContainer a{
    margin-top: 15px;
    color: #ea4335;
    font-weight: 700;
  }

  .rememberLabel{
    font-size:15px;
    font-weight:bold;
    margin-left:10px;
  }

  .loginbtnContainer button a{
    text-decoration: none;
    color: black;
  }


  @media (min-width:1260px){
    .applebtn{
      margin-top: auto;
    }

    .fblogin{
      margin-top: auto;
    }
  }

  
  @media (max-width: 900px){

    .loginbtnContainer{
      flex-direction: column;
      align-items: center;
    }

    .fblogin{
      margin-top: 8px;
    }
  }

  @media (max-width: 500px){

    .login .main{
      width: 47%;
    }

    .inputContainer .forgotContainer{
      flex-direction: column;
      align-items: flex-start;
    }

    .inputContainer .forgotContainer a{
      margin-bottom: 10px;
      margin-top: 7px;
    }

    .loginbtnContainer{
      flex-direction: column;
      align-items: start;
    }

  }