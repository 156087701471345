
.pages{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    padding: 30px;
}

.pages h3{
    font-weight: bold;
}

