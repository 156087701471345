.adminLoginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parentInputContainer{
    width: 70% !important;
}

.adminLoginForm .adminInputsConatiner{
    margin: 10px 0px !important;
}

.adminLoginForm .validationErr{
    color: red;
}

.login .cardGroupContainer{
    margin: 0px !important;
    width: 70% !important;
}

.login .centerContainer{
    display: flex;
    justify-content: center;
}


.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
    width: 60% !important;
    margin-left: 10px;
}


 @media (min-width: 1200px){
.col {
    flex: 0 0 auto;
    width: 55% !important;
}

}

@media (max-width: 1200px){
    .col {
        flex: 0 0 auto;
        width: 80% !important;
    }
    
    } 