body, html {box-sizing: border-box;
overflow-x: hidden;}

.navbar{
  background-color: white;
  border-bottom: 2px solid lightgray;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  left: 0;

}

.logo img{
    width: 70px;
}

.logo a{
    text-decoration: none;
}

.logo h2{
  color: rgb(239,63,62);
  font-weight:bold;
  cursor:pointer;
}


.navbar-content .dropdown{
        position: relative;
        display: inline-block;
      
}

.navbar-content .dropdown-content {
    display: none;
    position: absolute;
    background: white;
    border-radius: 7px;
    
    padding: 12px 16px;
    z-index: 1;
    margin-top: 2px;    
    
  }

  .navbar-content .dropdown-content li{
    margin-top: 5px;
    cursor: pointer;
    transition: ease .5s;
  }

  .navbar-content .dropdown-content li:hover{
    margin-left: 3px;
    color: rgb(255, 0, 64);
  }



  .navbar-content .dropdown:hover .dropdown-content {
    display: block;
  }

  .navbar-content li{
    list-style: none;

  }

  .navbar-content span{
    cursor: pointer;
    color: white;
    
  }

  .navbar-content .dropdown{
    margin: 10px;
  }

  .navbar-content{
    display: flex;
    align-items: center;
  }


  .navbar-content a{
    color: black;
    display: flex;
    align-items: baseline;
    grid-column-gap: 8px;
  }

  .navbar-content a:hover{
    color: black;
  }

  .navbar-content li{
    margin-left: 20px;
    color: black;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    grid-column-gap: 8px;
  }

  .navbar-content li .cartDot{
    position: absolute;
    top: -10px;
    left: 11px;
    background: red;
    border-radius: 50%;
    height: 15px;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
  }

  .navbar-content li i{
    color: black;
  }

  .selection{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  .selection select{
    margin-left: 10px;
    padding: 5px;
    background: none;
    border: none;
    color: black;
    font-weight: bold !important;
    background-color: aliceblue;
    padding: 8px 8px 8px 8px;
  }

  .home-login svg {margin-top: -5px;}


  .selection select option{
    color: black;
 
    margin-top: 3px;
    background-color:#fff;
  }

.emailVerify h1 {font-size: 16px !important;
  color: green !important;
  font-weight: normal !important;

} 

  .selection .login{
    margin-left: 10px;
  }



  .fa-bars{
    display: none;
  }

 .hammenu{
    background-color: white;
    color: white;
    position: fixed;
    top: 0%;
    right: 0%;
    width: 180px;
    height: 100vh;
    z-index: 100;
    display: none;
    transition: all ease-in-out .5s;
    animation: ham-animation ease-in-out .5s;
  }
.eWjCzc {min-width: auto !important; width: auto !important;  height: auto !important; font-size: 15px !important; background-color: white !important; border-radius: 10px !important; line-height: normal !important; padding: 10px 14px 10px 15px !important;} 

.subscribe {margin-top: 50px !important; margin-bottom: 50px !important; margin: auto;}

  @keyframes ham-animation{
    from{
      width: 90px;
    }

    to{
      width: 180px;
    }
  }



  

  
  .hammenu .forflex .navbar-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
  }
  
  .hammenu .forflex #navcontent i{
    color: black;
  }

  .hammenu .forflex #navcontent li{
    color: black;
    margin-top: 20px;
  }

  .hammenu .forflex #navcontent li a{
    color: black;
  }

  .hammenu .forflex{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 5%;
    display: none;
  }


  .back{
    color: black;
    font-weight: bold;
    position: fixed;
    top: 5%;
    z-index: 1000;
    right: 0;
    margin-right: 103px;
    cursor: pointer;
    display: none;
    transition: ease-in-out .5s;
    animation: back-animation ease-in-out .5s;
  }

  @keyframes back-animation{
    from{
      margin-right: 50px;
    }

    to{
      margin-right: 103px;
    }
  }




  .login{
    background: none;
  }

  .login h5{
    color: white;
    font-weight: bold;
    margin-top: 5px;
    margin-left: 5px;
    
  }

  .login button{
    margin-left: 13px;
    
  }

  .login a{
    text-decoration: none;
    color: black;
    font-weight: 700;
    margin-left: 10px;
  }

  .login a:hover{
    color: black;
  }

  .login .signupbtn{
    background: transparent;
    color: rgb(239,63,62);
    font-weight: bold;
    border: 1px solid rgb(239,63,62);
  }


  .login .signupbtn:hover{
    background: rgb(239,63,62);
    color: white;
  }

  .login .loginbtn{
    background: rgb(239,63,62);
    color: white;
    border: 1px solid rgb(239,63,62);
    font-weight: bold;
  }

  .login .loginbtn:hover{
    background: transparent;
    color: rgb(239,63,62);
  }

 .login .show{
  display: none;
 }

 .dropbtn {
  background: none;
  color: black;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: .8px;
  width: 130px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
 

  background-color: #f9f9f9;
  min-width: 130px;
  height: fit-content;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
 
  left: 25px !important;
}
.changePassword {border-bottom: solid 1px  rgba(0,0,0,0.2) ; padding-bottom: 5px;}

.dropdown-content2{
  height: 120px;
}

.dropdown-content2 .logoutbutton{
  margin-top: 15px;
}

.dropdown-content .logoutbutton {
  color: black;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 5px;
 padding-left: 15px;
  margin-top: 8px;
  display: block;
  
}

.dropdown-content .logoutbutton:hover{background-color: #f3f3f3;}

.dropdown-content .logoutbutton:hover {background-color: none;}
.dropdown:hover .dropdown-content {display: block;}

.fa-right-from-bracket{
  color: red;
}


  @media (max-width: 786px) {

    .hammenu .forflex{
      display: block;
    }
   

    .navbar-content{
      display: none;
    }

    .fa-bars{
      display: block;
    }

    .navbar{
      height: 100px;
    }

    .login button{
      margin-left: 4px;
    }
    .strechContainer {top: -50% !important; }
    /* .banner {height: 70vh;} */
    .banner .search {left:auto;}

    .offerSection {margin-top: 50px;}
   .offer-text  {display: block; text-align: center;  margin: auto; margin-left: 0px;}

   #ham {margin-left: 15px;}

  

  }


  @media (max-width: 540px)   {
    .banner .search {
      margin-left: 15px;
      display: block;
      height: auto;
     
      padding-bottom: 15px;
    
    
    }
    .search div {margin-top: 15px;}

    .strechContainer {top: -55% !important;}
    .search  button {margin-top: 15px;}

  }



