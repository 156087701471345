.subscribe{
    width:100%;
   
    align-items: center;
    display: flex;
  justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f7f7f7;
}

.sc-iBkjds {grid-column-gap: 10px;}
.subscribe .right{
    width: 40%;
}


.subscribe .left div{
    display: flex;
    align-items: center;
    background: white;
    height: 60px;
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 25px;
  justify-content: space-between;
  padding: 15px;
}


.subscribe .left div button{
    background: rgb(239,63,62);
    color: white;
    border: none;
    font-weight: bold;
    padding-top: 8px !important;
    padding-bottom: 9px !important;
    border-radius: 50px !important;
   
}

.subscribe .left div button:hover{
    background: white;
    color: rgb(239,63,62);
    border: 1px solid rgb(239,63,62);
    box-shadow:  rgb(239,63,62) 0px 2px 5px;
}

.subscribe .line {
    border-left: 6px solid rgb(239,63,62);
    height: 30px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 15%;
  }

  .left{
    position: relative;
  }

  .left p{
    position: absolute;
    bottom: -80%;
   
    color: green;
    font-size: 20px;
  }

  

  @media (max-width: 786px){

    .subscribe{
        flex-direction: column;
    }

    .subscribe .line{
        display: none;
    }

    .subscribe .right{
        width: 80%;
    }

    .subscribe .left div{
        width: fit-content;
        padding: 5px;
        
    }

    .left p{
        left: 3%;
      }
  }