
.form-group {
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
  align-items: "center";
}
.flag {display: flex !important; align-items: center !important;}
.flag span {margin-left: 15px;}

#panel3a-content {background-color: #f9f9f9; padding: 15px; border-radius: 15px;}
.contact-box .container{max-width: 900px !important; width: 900px;}
.number-details {padding-top: 15px;}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {padding: 0px 0px !important;}
.css-15v22id-MuiAccordionDetails-root {padding: 0px !important; margin-bottom: 10px;}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {box-shadow: 0px 0px 0px 0px !important;}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {min-height: 0px !important;}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {min-height: 0px !important;}
.css-o4b71y-MuiAccordionSummary-content {margin: 0px !important;}
#panel1a-header {border-radius: 0px !important;background-color: #f9f9f9; padding: 10px !important; margin-bottom: 20px; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);}
#panel2a-header {border-radius: 0px !important;background-color: #f9f9f9; padding: 10px !important; margin-bottom: 20px; box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);}
#panel3a-header { border-radius: 0px !important; padding-bottom: 6px !important;}

.css-keokkq-MuiGrid-root>.MuiGrid-item {align-items: baseline;}

#panel1a-header p:first-child{ font-weight: bold; color: #000 !important;}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {display: none;}
.MuiTypography-root b {margin-left: 15px; color: black;}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root strong{color: #000;}
.css-o4b71y-MuiAccordionSummary-content svg { color: #ef3f3e;}
.get-para {margin-top: 15px;}
.wp-text {margin-top: 15px;}
.wp-bt {display: block !important; text-align: center; margin-top: 20px;}
.call-para {margin-top: 15px; display: flex; justify-content: space-between; align-items: center;}
#panel2a-content {background-color: #f9f9f9; padding: 15px; border-radius: 15px;}
.number-details {display: flex;  justify-content: space-between;}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {    margin: 0px 0 !important;}

#panel3a-header p {margin-bottom: 0px !important; color: rgb(239,63,62); font-weight: 600; }
.css-ahj2mt-MuiTypography-root { color: #312e2e;}

.wishlist-2 .MuiButtonBase-root {height: 40px !important;}
.bkyt {margin-right: 40px !important; width: 20% !important;}
.bkyt-2 {margin-right: 40px !important; width: 20% !important; }
.book-fm {display: flex !important; margin-bottom: 20px;
  align-items: center; flex-wrap: wrap;}
  .text-frm {margin-bottom: 20px;}
  .book-fm input {background-color: #fff; color: #1a2b49; border: 1px solid rgba(196, 193, 193, 0.685);width: 50% !important; padding-left: 10px; padding-top: 4px; padding-bottom: 3px;}
  .book-fm input::placeholder{color: #1a2b49 !important;}
  .css-sghohy-MuiButtonBase-root-MuiButton-root {padding: 8px 16px !important;}
  .book-fm textarea {background-color: #fff; color: #1a2b49; border: 1px solid rgba(196, 193, 193, 0.685);width: 50% !important; padding-left: 10px; padding-top: 4px; padding-bottom: 3px;}
  .book-fm textarea::placeholder{color: #1a2b49 !important;}
  .logbtn {display: block; margin: auto; background-color: #f53; text-align: center; margin-top: auto !important; margin-left: auto !important; border-radius: 50px; border: 0px;padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 12px; color: #fff;}
.sub-accordion {margin-bottom: 0px !important;}

    .logbtn:hover{transition: 0.3s ease-in-out;
      background-color: white !important;
      color: #ef3f3e;
      
      -webkit-box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 22%);
      box-shadow: 1px 11px 20px 0px rgb(239 63 62 / 22%);}

.contact-info {margin-top: 120px;}
.css-ahj2mt-MuiTypography-root p:first-child{margin-bottom: 0px !important;}

@media only screen and (max-width:786px){
  .container {width: 100% !important; padding-left: 15px !important; padding-right: 15px !important; max-width: 100% !important;}

}