.react-tabs {
  display: flex;
  /* margin-left: 36px; */
  /* width: 470px; */
  /* height: 400px; */
  /* border: 1px solid #3c3e43; */
  /* color: white; */
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0;
  padding: 0;
  /* color: white; */
  /* background: #3c3e43; */
  border: solid 1px #000;
  height: 405px;
}
.panel-content h3 {font-size: 16px; font-weight: 700;}
.panel-content h2 {font-size: 16px; font-weight: 700;}
.panel-content hr  {margin-top: 0px;}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 20px 6px;
  cursor: pointer;
  /* border-color: #1c1d1f; */
  /* border: 1px solid #2d2e2d; */
  /* color: #bbb; */
}

.react-tabs__tab--selected {
  background: #f4f4f4;
  border: none;
  /* border-color: #1c1d1f;
  border-left: 4px solid #6eb800; */
  /* color: white; */
}

.react-tabs__tab-panel {
  display: none;
  /* width: 300px; */
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 24px;
}

.panel-content {
  padding-left: 50px;
  /* text-align: center; */
}

.profile-overview {
  text-align: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    24deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(68, 68, 136, 1) 91%
  );
  color: #fff;
  padding: 70px 0;
}
.profile-overview .name {
  font-size: 20px;
}

.profile-content {width: 80%;}
@media  only screen and (max-width:991px){
  .kep-login-facebook {margin-top: 10px;
    margin-bottom: 10px;}
}

@media only screen and (max-width:750px){
  .react-tabs {display: block; }
  .react-tabs__tab-list {width: 100%; margin-bottom: 30px;}
  .panel-content {padding-left: 0px;}
  .css-1pswi06 .MuiTextField-root {width: 100% !important;}
  .kep-login-facebook {margin-top: 10px;
  margin-bottom: 10px;}
  .searchBar .searchInput {width: 100%; padding: 0px 10px;}
  .searchBar input {width: 100%;}
}
