
.experience{
    background: white;
    position: relative;
    width: 100%;
    height: fit-content;
    margin-left: 30px;
    margin-bottom: 15px;
    border-radius: 25px;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 25%);
    overflow: hidden;
}

.experience .fa-heart{
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 25px;
    color: white;
    cursor: pointer;
    z-index: 1000;
    filter: drop-shadow(2px 4px 6px black);
}

.offer-text  div:first-child{font-size: 14px;
    color: white;
    background-color: #ef3f3e;
    text-transform: uppercase !important;
    padding: 4px 18px !important;
    width: auto !important;
    border-radius: 50px !important;
    line-height: 1.7;}

.experience img{
    border-radius: 25px;
    width: 100%;
    height: 290px;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 50%);
    cursor: pointer;
    transition: ease .5s;
}


.experience:hover img{
    transform: scale(1.04);
}

.offerInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40%;
    padding: 10px 20px;
}

.offerInfo .rating{
    margin-left: 20px;
}

.offerInfo .rating i{
    font-size: 13px;
    margin-left: 5px;
    color:#FFCC33;
}



.offerInfo .ratingbox{
    width:100%;
    display: flex;
}

.offerInfo .ratingbox a{
    text-decoration: none;
    font-weight: bold;
    color: black;
}

.offerInfo .ratingbox a h6:hover{
    color: #EF3F3E;
}

.offerInfo .ratingbox h6{
    font-weight: bold;
    line-height: normal !important;
    cursor: pointer;
}

.offerInfo .detail{
    color:grey;
    margin-top:5px;
    font-size: 15px;
    width: 100%;
}

.offerInfo .tripduration{
    display:flex;
    margin-top:5px;
    justify-content:space-between;
    width:100%;
}


.offerInfo .tripduration h6{
    color:#EF3F3E;
    text-transform:uppercase;
    font-size: 13px;
}

.price .offertitle{
    background:#EF3F3E;
    width:100px;
    color:white;
    border-radius:10px;
    padding-left:15px;
}

.price .pricetitleContianer{
    display:flex;
    background:rgba(128, 128, 128, 0.336);
    width:130px;
    height:25px;
    color:white;
    border-radius:10px;
    padding-left:15px;
    padding:2px;
    margin-top:5px; 
}

.price .pricetitle{

    text-decoration:line-through;
    margin-right:5px;
    font-size:14px;
}

.experience .price{
    position: absolute;
    top: 5%;
    left: 5%;
}

