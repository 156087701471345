.card-cursor {
  cursor: -webkit-grab;
  cursor: grab;
}


.wishlistMsg{
  background: lightgreen;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.wishlistMsg p{
    margin-bottom: 0px;
    color: darkgreen;
}


.fixedMsg{
    background: lightgreen;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: fixed;
    top: 5%;
    left: 40%;
    z-index: 100;
}

.fixedMsg p{
  margin-bottom: 0px;
  font-size: 17px;
  color: darkgreen;
}

.wishlistModalContain{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wishlistModalContain .wishlistModal{
   background: white;
    width: 50%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 20px;
}

.wishlistModal .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  margin-top: 25px;
}


.wishlistModal .fa-xmark{
  position: absolute;
  left: 5%;
  font-size: 30px;
  cursor: pointer;
}

.wishlistModal .fa-xmark:hover{
  color: rgb(239,63,62);
}

.wishlistModal h5{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.wishlistModal .listBtn{
    background: none;
    border: none;
    position: absolute;
    right: 5%;
    font-size: 15px;
    font-weight: 600;
    color: cornflowerblue;
}

.wishlistModal .listBtn:hover{
  color: rgb(21, 96, 235);
}

.wishlistModal .noList{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.noList span{
  margin-top: 10px;
  font-weight: 600;
}

.noList button{
  margin-top: 20px;
  padding: 10px;
  font-size: 15px;
  font-weight: 600;
  background: rgb(239,63,62);
  border: 2px solid rgb(239,63,62);
  border-radius: 23px;
  color: white;
  width: 35%;
}

.noList button:hover{
  background: white;
  color: rgb(239,63,62);
}

.wishlistModal .listsAvailable{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    padding: 8px 0px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    border: solid 1px lightgray;
   
    padding-left: 15px;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);
}

.wishlistModal .listsAvailable:hover{
  background: gainsboro;
}

.listsAvailable img{
  width: 80px;
  height: 75px;
}

.listsAvailable .listsInfo{
  margin-left: 30px;
}

.listsInfo span{
  font-size: 16px;
  font-weight: 500;
}

.listsInfo p{
  font-weight: 500;
  margin-bottom: 0px;
  color: gray;
}


.css-1ot0g1q-MuiGrid2-root {padding: 0px !important;}
.css-1ot0g1q-MuiGrid2-root svg {color: rgb(239,63,62);}
.css-p7kc4m-MuiGrid2-root {padding: 0px !important;}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {background-color: transparent !important;  color: rgb(239,63,62) !important;}
.css-1oqv2c0-MuiPaper-root-MuiCard-root {box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 50%) !important;}
.noList-title {font-size: 16px;}

@media only screen and (max-width:640px){
  .wishlistModalContain .wishlistModal{
    
     width: 100%;}
}