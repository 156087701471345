.pageLoading{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageError{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resetPassword{
    width: 100%;
    background: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.resetPassword .loginbox{
    background:grey;
    position:absolute;
    top:30%;
    left:50%;
    width:150px;
    height:120px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    color:white;
    opacity:0.3
  }


.resetPassword .resetContain{
    display: flex;
    flex-direction: column;
    background: white;
    height: fit-content;
    width: 40%;
    padding: 10px;
    margin: 30px 0px;
    position: relative;
  }

.resetContain p{
    color: red;
}  

.resetPassword .resetContain #msg{
    color: green;
    position: absolute;
    top: 9%;
}

.resetPassword .resetContain #errormsg{
    color: red;
    position: absolute;
    top: 9%;
}

.resetPassword .resetContain h4{
    font-size: 17px;
    font-weight: 700;
  }

.resetPassword .resetContain label{
    font-weight: 500;
    margin-top: 15px;
  }

.resetPassword .resetContain input{
    border: 1px solid rgba(196, 193, 193, 0.685);
    padding: 5px;
    margin-top: 10px;
  }

.resetPassword .resetContain button{
        margin-top: 20px;
        background: rgb(239,63,62);
        color: white;
        border: 1px solid rgb(239,63,62);
        font-weight: bold;
        padding: 5px;
        transition: ease .3s; 
  }

.resetPassword .resetContain button:hover{
    background: white;
    color: rgb(239,63,62);
}


@media (max-width:620px){
    .resetPassword .resetContain{
        width: 60%;
    }
}