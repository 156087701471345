.Cart{
    width: 100%;
    height: fit-content;
}

.Cart .cartError{
  text-align: center;
    font-weight: 700;
}

.cart-title {height: 20vh !important;}
.Cart .cartLoader{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartError {width: 20% !important;
    margin: auto;
    margin-bottom: 20px;
    background-color: rgb(239,63,62);
    height: auto !important;
    padding-top: 30px !important;
    padding-bottom: 25px !important;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.80);
  }
  .cartError  h4 {text-transform: lowercase; color: #fff;}
  


.Cart .cartLoader{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartTitle{
    height: 35vh;
    margin-right: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.pr {display: flex;
    grid-column-gap: 10px;}
.cartTitle h4{
    font-weight: 700;
}


.cartTitle span{
    font-weight: 600;
    color: red;
    cursor: pointer;
    margin-bottom: 0.5em
}

.Cart .cartContainer{
    display: flex;
    justify-content: space-between;
}


.cartContainer .cartDestination{
    display: flex;
}

.cartContainer .cartDestination{
    width: 90%;
    margin-top: 20px;
    border: 1px solid #dcdfe4;
    border-left: none;
    border-right: none;
    border-top: 3px solid #ef3f3e;
    padding: 10px;
}

.removeCartMsg{
    position: fixed;
    top: 3%;
    left: 40%;
    background: lightgreen;
    padding: 15px;
    border-radius: 6px;
}

.removeCartMsg h6{
    color: darkgreen;
}

.errorCartMsg{
    position: fixed;
    top: 3%;
    left: 40%;
    background: rgb(253, 107, 107);
    padding: 15px;
    border-radius: 6px;
}

.errorCartMsg h6{
    color: rgb(139, 1, 1);
}

.cartDestination .desDetails{
    margin-left: 10px;
}



.desDetails .desTitle{
    display: flex;
    justify-content: space-between;
}

.desDetails .desTitle h6{
    width: 85%;
}

.desDetails .cartStars{
    display: flex;
    align-items: center;
}

.desDetails .cartStars .css-ryrseu-MuiRating-root{
    margin-left: 5px;
}

.desDetails .ratingPrice{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}

.desDetails .ratingPrice p{
    margin-bottom: 0px;
}

.desDetails .cartDesPersons{
    font-weight: 700;
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desDetails .cartDesPersons .btn-group, .btn-group-vertical {
    width: 35px;
    height: 28px;
}

.desDetails .cartDesPersons .dropdown-menu {
    min-width: 2rem !important;
}

#cartDatePicker{
    display: none;
}

#cartDatePicker .chngDateBtn{
    border: none;
    background: none;
    font-weight: 600;
    color: red;
}

#personDropdown{
    display: none;
}

#cancelBtn{
    display: none;
}

.desDetails .cartDesDate{
    font-weight: 700;
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.desDetails .cartDesDate .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 70% !important;
}

.desDetails .cartDesDate .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    display: none;
} 

.desDetails .cartDesDate .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    height: 35% !important;
}

.desDetails .cartDesDate .btn-group, .btn-group-vertical {
    height: 28px !important;
}

.desDetails .cartDesDate .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 3.5px 14px !important;
}

.desDetails .cartDesDate .dropdown-menu {
    min-width: 2rem !important;
}


.desDetails .cartDesStartingTime{
    font-weight: 700;
    margin-top: 7px;
}


.desDetails .cartLang{
    font-weight: 700;
    margin-top: 7px;
}

.desDetails .cartCancel{
    font-weight: 700;
    color: #67687A;
    margin-top: 7px;
}


.desDetails .desTitle i{
    cursor: pointer;
    color: red;
}


.desDetails button{
    padding: 0px;
    margin-top: 7px;
}

.cartDestination .desImage img{
    width: 100px;
}

.cartChangeBtn{
    border: none;
    background:none;
    color: red;
    font-weight: 600;
}


.cartContainer .cartCheckout{
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    border: 1px solid #dcdfe4;
    border-top: 3px solid #ef3f3e;
    padding: 10px;
    margin-bottom: 20px;
}


.cartContainer .cartDiscount{
    color: #f33333;
    font-weight: 500;
    cursor: pointer;
}

.cartContainer .redeemContainer{
    display: none;
}

.cartContainer .redeemContainer label{
    font-weight: 600;
}

.cartContainer .redeemContainer input{
    padding: 4px;
    border: 2px solid lightgrey;
    border-radius: 7px;
}


.cartContainer .redeemContainer button{
    background: none;
    padding: 4px;
    width: 85px;
    border-radius: 16px;
    border: 2px solid #f33333;
    color: #f33333;
    margin-left: 10px;
    font-weight: 600;
}



.cartCheckout .checkoutTitle{
    display: flex;
    justify-content: space-between;
}

.checkoutTitle .checkoutPrice{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 11px;
    font-weight: 600;
    color: green;
}

.checkoutPrice div{
    display: flex;
    align-items: center;
}

.checkoutPrice div span{
    text-decoration-line: line-through;
    font-size: 14px;
    margin-bottom: 12px;
    margin-top: 0px;
    margin-right: 5px;
}

.cartCheckout button{
    width: 100%;
    border:none;
    padding: 8px;
    font-size: 17px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    font-weight: 700;
    color: white;
    background: #ef3f3e;
}

.cartCheckout span{
    margin-top: 10px;
    color: #67687A;
    font-weight: 700;
}


/* gift section */

.Cart .giftsTitle{
    margin-top: 20px;
    font-weight: 700;
}


.cartGifts{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 25px;
    border-top: 3px solid #ef3f3e;
    border-bottom: 1px solid #dcdfe4;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cartGifts img{
    width: 30%;
}

.cartGifts .giftInfo{
    width: 65%;
}


.cartGifts h5{
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 700;
}

.cartGifts p{
    font-weight: 600;
    color: gray;
}

.giftInfo .giftPrice{
    display: flex;
    justify-content: space-between;
}

.giftPrice span{
    font-size: 17px;
    font-weight: 600;
}

.giftPrice button{
    border: none;
    width: 102px;
    font-size: 15px;
    font-weight: 600;
    color: red;
    background: none;
}

.giftPrice button:hover{
    background: #8080800f;
}


@media only screen and (max-width:1040px){
    .Cart .cartContainer { flex-wrap: wrap; width: 100%;
        padding-left: 15px;
        padding-right: 15px;} 
    .cartTitle {margin-left: 15px; margin-right: 15px;}
   
}

@media only screen and (max-width:991px){
    .cartContainer .cartDestination {width: 100%;}
    .cartGifts {width: 100%;}
}

@media only screen and (max-width:640px){
    .cartError {width: 80% !important;}
  }

@media only screen and (max-width:480px){
    .login button {
        margin-left: -18px;
    }
  
}